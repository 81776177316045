import { pipe, Match } from 'effect'
import * as FSA from '@repo/schema'
import React from 'react'
import { LoaderFunction, useLoaderData } from 'react-router-dom'

import { AudioDescription } from './kinds/AudioDescription'
import { Description } from './kinds/Description'
import { Document } from './kinds/Document'
import { EqTD } from './kinds/EqTD'
import { FredsNotes } from './kinds/FredsNotes'
import { Object } from './kinds/Object'
import { Picture } from './kinds/Picture'
import { Transcript } from './kinds/Transcript'
import { Video } from './kinds/Video'
import { getArtifact } from './getArtifact'
import { BaseArtifact } from './BaseArtifact'
import { AllArtifactEntries } from './types'

type ArtifactResult = Awaited<ReturnType<typeof getArtifact>>['primary']
const ErrorMsg: React.FC<{ message: string }> = ({ message }) => <div>There was a problem loading the entry: {message}</div>
export const getArtifactMatcher = pipe(
  Match.type<ArtifactResult>(),
  Match.tag('BadId', ({ message }) => <ErrorMsg message={message} />),
  Match.tag('ClientError', ({ message }) => <div>There was an error loading this entry: {message}</div>),
  Match.tag('GetArtifact', ({ requested, primary, subArtifacts: secondary }) =>
    <BaseArtifact entry={requested} artifact={{ primary, secondary }}>
      {MatchArtifactKind(requested, { primary, secondary })}
    </BaseArtifact>
  ),
  Match.exhaustive,
)

export const MatchArtifactKind = (entry: FSA.Artifact, artifact: AllArtifactEntries) => pipe(
  entry,
  Match.value<FSA.Artifact>,
  Match.when({ kind: { name: 'AudioDescription' } }, (entry) => <AudioDescription entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'Description' } }, (entry) => <Description entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'Document' } }, (entry) => <Document entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'EqTD' } }, (entry) => <EqTD entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'FredsNotes' } }, (entry) => <FredsNotes entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'Object' } }, (entry) => <Object entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'Picture' } }, (entry) => <Picture entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'Transcript' } }, (entry) => <Transcript entry={entry} artifact={artifact} />),
  Match.when({ kind: { name: 'Video' } }, (entry) => <Video entry={entry} artifact={artifact} />),
  Match.orElseAbsurd
)

export const ArtifactLoader: LoaderFunction = async ({ params }) => getArtifact(params.id)
const useArtifactLoader = () => useLoaderData() as Awaited<ReturnType<typeof getArtifact>>
export const ArtifactRouter: React.FC = () => getArtifactMatcher(useArtifactLoader().primary)

//export type ValuesOf<T> = T extends { [_ in keyof T]: infer U } ? U : never
