import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $Clip, $MP4, $Duration, $Topic } from "../types"
import { addAnnotations } from "../addAnnotations"
import { OptionalArray } from "../types/utils"

export const VideoKindModel = {
  code: 'V',
  name: 'Video',
  extensions: [$MP4],
  description: 'Video',
  //description: "Icon representing a video entry",

  icon: 'icon_video',
  plural: 'Videos',
  alt: "An icon that represents videos in the Fred Sammons Archive database.",
  searchFields: ["Titles & Keywords", "Descriptions", "Captions"],
} as const satisfies BaseKindModel

const _$VideoKind = createArtifactKind(VideoKindModel)
  .pipe(S.extend(S.Struct({
    topics: OptionalArray($Topic, 'Topics'),

    // Should be (YoutubeIdFromVideoURL)
    youtubeID: S.String.pipe(addAnnotations({ identifier: 'Youtube-ID' }), S.optional()),
    
    clip: $Clip,
    duration: $Duration,
    longDescription: S.String.pipe(addAnnotations({ identifier: 'longDescription' }), S.optional()),
  })))

export interface VideoKindEncoded extends S.Schema.Encoded<typeof _$VideoKind> { }
export interface VideoKind extends S.Schema.Type<typeof _$VideoKind> { }
export interface $VideoKind extends S.Annotable<$VideoKind, VideoKind, VideoKindEncoded> { }
export const $VideoKind: $VideoKind = _$VideoKind
