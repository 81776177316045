import './public/css/style.css'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider, } from "react-router-dom"
import { QueryStateProvider } from './hooks/useQueryState'
import { RootRouter } from './router'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <StrictMode>
    <QueryStateProvider>
      <RouterProvider router={RootRouter} />
    </QueryStateProvider>
  </StrictMode>
)
