import React from 'react'
import { CheckboxEras, TypePicker } from '../components/Pickers'
import { Form } from 'react-router-dom'
import { useSearchState } from '../hooks/useSearch'
import { SearchText } from '../components/Search/SearchText'
import { useQuerySubmitter } from '../hooks/useQueryState'

export const Home: React.FC = () =>
  <div className="Home">
    <HomeSearch />
  </div>

export const HomeSearch = () => {
  const { queryState } = useSearchState()
  const handleSubmit = useQuerySubmitter(() => queryState)
  const { phrase, types, eras } = queryState

  const searchFields = queryState
    .searchFields
    .map(name => <div className='field' key={name}><p>{name}</p></div>)

  return <Form className='search' role='search' onSubmit={handleSubmit}>
    <SearchText value={phrase.value} onChange={phrase.setValue} />

    <p>Refine your search by selecting parameters below.</p><hr />

    <TypePicker picker={types} label='Artifact Types' />
    <hr />
    <CheckboxEras picker={eras} label="Fred's Eras" />
    <hr />

    <button type="submit" className="SearchButton">Search</button>
  </Form>
}

