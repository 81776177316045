import { Record as RR, } from "effect"

export const invertRecord = <R extends Record<string, string>>(record: R) =>
  RR.mapEntries(record, (value, key) => [value, key]) as Invert<R>

export type Invert<T extends Record<PropertyKey, PropertyKey>> = {
  [P in keyof T as T[P]]: P
}
export function typeSafeObjectFromEntries<const T extends ReadonlyArray<readonly [PropertyKey, unknown]>>(
  entries: T
): { [K in T[number]as K[0]]: K[1] } {
  return Object.fromEntries(entries) as { [K in T[number]as K[0]]: K[1] };
}
export function typeSafeObjectEntries<T extends Record<PropertyKey, unknown>>(
  obj: T
): { [K in keyof T]: [K, T[K]] }[keyof T][] {
  return Object.entries(obj) as { [K in keyof T]: [K, T[K]] }[keyof T][];
}
export type Nth<T, Idx extends number = 0> = T extends ReadonlyArray<any> ? T[Idx] : T
export type MapNth<T, Idx extends number = 0> = { [K in keyof T]: Nth<T[K], Idx> }



// type UnionToIntersection<U> =
//   (U extends any ? (x: U) => void : never) extends ((x: infer I) => void) ? I : never
// type NarrowType<T> = string extends T ? never
//   : number extends T ? never
//   : symbol extends T ? never
//   : T

// type RemoveIndex<T> = { [K in keyof T as NarrowType<K>]: T[K] }
// type KnownKeys2<T> = ({ [K in keyof T as NarrowType<K>]: K })// extends { [_ in keyof T]: infer U } ? U : never;
// type A = RemoveIndex<KindCodeNamePairs>
// type B = KnownKeys2<KindCodeNamePairs>
// type C = KnownKeys<KindCodeNamePairs>
// type D = keyof KindCodeNamePairs
// type KnownKeys<T> = keyof RemoveIndex<T>
export type ValuesOf<T> = T extends { [_ in keyof T]: infer U } ? U : never
// type Values<T> = T[KnownKeys<T>]


/**
 * Maps each element of a tuple to a new tuple consisting of selected properties.
 * This function now accepts readonly tuples, allowing for immutable data structures to be processed.
 *
 * @param tuple - The tuple of objects to map, which can be a readonly tuple.
 * @param propA - The key of the first property to extract from each object.
 * @param propB - The key of the second property to extract from each object.
 * @returns A new tuple where each element is a tuple of the specified properties from the original tuple's objects.
 * @example
 * const data = [{ code: '001', name: 'Item A' }, { code: '002', name: 'Item B' }] as const;
 * const result = mapTuple(data, 'code', 'name');
 * // Output: [['001', 'Item A'], ['002', 'Item B']]
 */
export function mapTuple<T extends ReadonlyArray<Record<K1, any> & Record<K2, any>>, K1 extends keyof T[number], K2 extends keyof T[number]>(
  tuple: T,
  propA: K1,
  propB: K2
): MapTupleToTuple<T, K1, K2> {
  return tuple.map(item => [item[propA], item[propB]]) as MapTupleToTuple<T, K1, K2>;
}

/**
 * A utility type that maps each element of a readonly tuple to another tuple consisting of selected properties from each object.
 * It handles tuples as readonly to respect the immutable nature of data defined with `as const`.
 *
 * @typeparam T - A tuple type, where each element is an object.
 * @typeparam K1 - The type of the key representing the first property to extract.
 * @typeparam K2 - The type of the key representing the second property to extract.
 * @returns A tuple of tuples, each containing the specified properties from the corresponding element of the input tuple.
 */
export type MapTupleToTuple<T, K1 extends keyof any, K2 extends keyof any> = T extends readonly [infer First, ...infer Rest]
  ? First extends Record<K1, any> & Record<K2, any>
    ? [[First[K1], First[K2]], ...MapTupleToTuple<Rest, K1, K2>]
    : never
  : [];


/**
 * Transforms a tuple of objects into a mapped object keyed by a specified property.
 * Ensures type safety and maintains specific type associations for each key.
 *
 * @param items - The tuple of objects to transform.
 * @param key - The property name to key by.
 * @returns A statically typed map where each key directly corresponds to the type of the object from which it is derived.
 *
 * Usage example:
 * const users = [{ id: 1, name: "Alice" }, { id: 2, name: "Bob" }] as const;
 * const usersById = keyBy(users, 'id');
 * console.log(usersById);
 * // Output: { '1': { id: 1, name: "Alice" }, '2': { id: 2, name: "Bob" } }
 */
export function keyBy<
  T extends { [P in K]: PropertyKey },
  K extends keyof any
>(
  items: readonly T[],
  key: K
): { [P in T[K]]: Extract<T, { [key in K]: P }> } {
  return items.reduce((acc, item) => {
    const itemKey = item[key];
    acc[itemKey] = item as Extract<T, { [key in K]: typeof itemKey }>;
    return acc;
  }, {} as { [P in T[K]]: Extract<T, { [key in K]: P }> });
}
