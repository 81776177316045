import React from 'react'
import MediaElement from './MediaElement'
import { createMediaLink } from './utils'
import { Artifact } from '@repo/schema'
import { config } from '../config'

/**
 * Component for rendering MP3 audio.
 * @param props - The MP3 properties, including id, artifact, file, title, and count.
 * @returns A React element for the MP3 audio player.
 */
export const Mp3: React.FC<Mp3Props> = (props) => {
  const { id, title, count } = props
  const src = 'entry' in props
    ? createMediaLink(props.entry, { extension: '.mp3', count })
    : config.media_folder + `/${props.src}.mp3`

  return <div>
    {title && <h3>{title}</h3>}
    <MediaElement
      id={id}
      mediaType="audio"
      preload="none"
      controls
      poster=""
      sources={[{ src, type: 'audio/mp3' }]}
      options={[{ stretching: 'responsive' }]}
      tracks={[]} />
    <br />
  </div>
}

export type Mp3Props = (Mp3BaseProps & { src: string }) | (Mp3BaseProps & { entry: Artifact })
export interface Mp3BaseProps {
  id: string
  title?: string;
  count?: number
}
