import React from 'react'
import { Link } from 'react-router-dom'
import { R2D2 } from '../utils'
import { config } from '../../config'

export const AboutR2D2 = () =>
  <div>
    <div className="breadcrumb menu">
      <ul>
        <li><Link to="/about" className='item'>About the Archives</Link></li>
        <li><Link to="/about/about-fs" className='item'>About Fred Sammons</Link></li>
        <li><Link to="/about/about-r2d2" className='item active'>About the R<sub>2</sub>D<sub>2</sub> Center</Link></li>
      </ul>
    </div>
    <div>
      <div className="aboutImg"><img src="/img/r2d2team.png" alt="A Photograph of the Staff of the Rehabiliation, Research, Design, and Disability Center." /></div>
      <h1>About the <R2D2 /> Center</h1>
      <p>The <R2D2 /> Center, located at the University of Wisconsin-Milwaukee, performs interdisciplinary basic research investigations,
        applied research and development, and innovative instruction related to technology and disability.</p>
      <p>The Center was established within the College of Health Sciences as the Center for Rehabilitation Sciences and Technology
        (CRST). The Center's name was then changed to the Rehabilitation Research Design & Disability (<R2D2 />) Center in November 2003 to
        more accurately describe the Center's overall activities. </p>
      <p>The <R2D2 /> Center is affiliated with multiple colleges, schools, and service programs at the University of Wisconsin-Milwaukee
        and affiliates, organizations, agencies and universities throughout the U.S. and globally. The Center is directed by
        Dr. Roger O. Smith.</p>
    </div>
    <p>We'd love to hear from you! Please share with us how you're using the Fred Sammons Archives.</p>

    <div className="contactText">
      <h3>Email</h3>
      <p>Project questions, comments and suggestions:&nbsp<a href="mailto:{config.admin_email}?subject=Fred Sammons Archives Inquiry">{config.admin_email}</a>
      </p>
      <p>Website questions, comments and suggestions:&nbsp<a href="mailto:r2d2@uwm.edu?subject=Fred Sammons Archives Website Inquiry">r2d2@uwm.edu</a>
      </p>
      <h3>Mailing Address</h3>
      <p>ATTN: Fred Sammons Archives Project</p>
      <p><R2D2 /> Center</p>
      <p>Enderis Hall 135</p>
      <p>PO Box 413</p>
      <p>Milwaukee, WI 53201</p>
      <p>USA</p>

      <h3>Street Address</h3>
      <p>Enderis Hall 135, <R2D2 /> Center</p>
      <p>2400 E. Hartford Ave</p>
      <p>Milwaukee, WI 53211</p>
      <p>USA</p>

      <h3>Telephone</h3>
      <p>voice (414) 229-6803</p>
      <p>fax (provided upon request)</p>
    </div>
  </div>
