import React from 'react'
import { AudioDescriptionArtifact, DocumentArtifact } from "@repo/schema"
import { createMediaLink, ReactFCC } from "../../utils"
import { EntryArtifactFC, EntryFC } from "../types"
import { Categories, DownloadLink } from '../sections'
import { EntryProperty } from '../utils'
import { Topics } from './common'
import { AudioDescription } from './AudioDescription'

export const Document: EntryArtifactFC<DocumentArtifact> = ({ entry, artifact }) => {
  //Expected file type: 1.pdf (no counts)
  //const downloadLinkEmbed = 
  const downloadLink = createMediaLink(entry)
  const audioDescriptions = artifact.secondary
    .filter(secondary => secondary.kind.name === 'AudioDescription') as AudioDescriptionArtifact[]
  return <>
    <DocumentPreview entry={entry} />
    <DownloadLink href={downloadLink}>View Full Document</DownloadLink>
    <DocumentProperties entry={entry} />
    {audioDescriptions.length > 0 && <section>
      <h2>Notes from Fred</h2>
      {audioDescriptions.map(audio => <AudioDescription entry={audio} artifact={artifact} />)}
    </section>}
  </>
}
const previewOptions = { count: 0, extension: '.png' } as const
export const DocumentPreview: EntryFC<DocumentArtifact> = ({ entry }) =>
  <div className="documentPreview">
    <h3>Preview of First Page of Document</h3>
    <img src={createMediaLink(entry, previewOptions)} alt={entry.shortDescription} />
  </div>

const DocumentProperties: EntryFC<DocumentArtifact> = ({ entry }) =>
  <dl>
    <Categories entry={entry} />
    <EntryProperty label="Document properties">{(entry.kind.formats ?? []).join(', ') || "None listed"}</EntryProperty>
    <Topics topics={entry.kind.topics ?? []} />
    {entry.kind.sheet && <EntryProperty label="Sheet #">{entry.kind.sheet}</EntryProperty>}
  </dl>


