import React from 'react'
import { clsx } from 'clsx'
import { Checkbox } from 'react-icheck'

import { TODO } from "../utils"
import { ToggleComponent, TogglerState } from "../Toggler"
import { PickerChoiceFC, PickerChoicesFC, PickerFC } from "./utils"

export interface PickerProps<Options extends ReadonlyArray<string>> {
  picker: TogglerState<Options>
  label: string
  component: TODO
}

// Checkbox picker
export const CheckboxPickerChoices: PickerChoicesFC<ReadonlyArray<any>> =
  ({ picker }) =>
    picker.choices.map(choice =>
      <div key={choice} className={clsx('choice', { highlight: picker.isChecked(choice) })}>
        <FSACheckBox
          label={choice}
          onChange={() => picker.toggle(choice)}
          checked={!picker.allSet && picker.isChecked(choice)} />
        <label className="choice-label">{choice}</label>
      </div>
    )

export const CheckboxPickerAll: PickerFC<any> =
  ({ picker, label }) =>
    <div className={clsx('choice', 'all-choice', { highlight: picker.allSet })}>
      <FSACheckBox
        label={`All ${label}`}
        onChange={picker.toggleAll}
        checked={picker.allSet} />
      <label className="choice-label">{label}</label>
    </div>

// Toggle Picker
export const TogglePicker: PickerChoiceFC<any> =
  ({ picker, choice }) =>
    <ToggleComponent
      label={choice.name ?? choice.value}
      className="choice"
      checked={!picker.allSet && picker.isChecked(choice.value ?? choice.name)}
      onChange={() => picker.toggle(choice.value ?? choice.name)} />

export const TogglePickerAll: PickerFC<any> =
  ({ picker, label }) =>
    <ToggleComponent label={label} className="choice all-choice"
      checked={picker.allSet}
      onChange={() => picker.toggleAll()} />

///// Util: FSAChecbox
interface FSACheckBoxProps {
  label: string
  checked: boolean
  onChange(): void
}
const FSACheckBox: React.FC<FSACheckBoxProps> =
  ({ onChange, checked, label }) =>
    <Checkbox
      checkboxClass="icheckbox_flat-yellow"
      increaseArea="20%"
      tabIndex="0"
      aria-label={label}

      onChange={onChange}
      checked={checked}
    />
