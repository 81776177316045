import * as S from "@effect/schema/Schema"
import { addAnnotations } from "../addAnnotations"

export const $EXTENSIONS = [".pdf", ".mp4", ".txt", ".mp3", ".jpg", ".srt"] as const
export const $PDF = S.Literal('.pdf')
export const $TXT = S.Literal('.txt')
export const $MP3 = S.Literal('.mp3')
export const $SRT = S.Literal('.srt')
export const $JPG = S.Literal('.jpg')
export const $MP4 = S.Literal('.mp4')
export const extensions = [$PDF, $TXT, $MP3, $SRT, $JPG, $MP4] as const

const _$MediaExtension = S.Union(...extensions)

export type MediaExtensionEncoded = S.Schema.Encoded<$MediaExtension>
export type MediaExtension = S.Schema.Type<$MediaExtension>
export interface $MediaExtension extends S.Annotable<$MediaExtension, S.Schema.Type<typeof _$MediaExtension>> { }
export const $MediaExtension: $MediaExtension = _$MediaExtension.pipe(addAnnotations({ identifier: "MediaExtension" }))
