import React from 'react'
import { TranscriptArtifact } from "@repo/schema"
import { EntryArtifactFC } from "../types"
import { createMediaLink } from "../../utils"
import { DownloadLink } from '../sections'

export const Transcript: EntryArtifactFC<TranscriptArtifact> = ({ entry, artifact }) =>
    <DownloadLink href={createMediaLink(entry, { extension: '.txt' })}>Download Transcripts of Video</DownloadLink>

// <p><a href={createMediaLink(entry, '-text.txt')}>Download Video Captions</a></p>
// <p><a href={createMediaLink(entry, '.txt')}>Download Timed Video Captions</a></p>
