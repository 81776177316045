import React from 'react'
import { ObjectArtifact } from "@repo/schema"
import { EntryArtifactFC } from "../types"
import { createMediaLink } from "../../utils"

// Objects, 5-jpegs, isObject
// Expected file type: 1.1.jpg (images)
// downloadLinkEmbed: <div className="downloadButtonCenter"><p><a href={mediaLink}> Download Media </a> </p> </div>,

export const Object: EntryArtifactFC<ObjectArtifact> = ({ entry, artifact }) =>
  <img src={createMediaLink(entry)} alt={entry.shortDescription} />
