import React, { CSSProperties } from 'react'
import { Artifact, } from '@repo/schema'
import { EntryArtifactProp, EntryFC, EntryProp } from '../Artifact/types'
import { BUTTON_GREEN, MAIN_YELLOW } from '../../styles'
import { ReactFCC } from '../utils'

type ArtifactProp = EntryProp<Artifact> & { style?: CSSProperties }
export const ArtifactIdTag: React.FC<ArtifactProp> = ({ entry, style = {} }) => {
  const styles = {
    ...ArtifactIdStyle,
    ...style
  }
  return <TagLabel {...styles}> Artifact #{entry.artifactId}</TagLabel >
}

export const EntryInfo: React.FC<EntryArtifactProp<Artifact> & { style?: CSSProperties }> = ({ entry, artifact, style = {} }) => {
  return <></>
  if (entry.isPrimary) return <TagLabel {...style}>Primary Entry</TagLabel>
  const counts = artifact
    .secondary
    .map(({ count }) => count)
    .filter(count => count !== undefined)
  const maxCount = Math.max(...counts)

  if (entry.count === undefined) console.log('No count for:', entry, artifact)

  const content = (entry.count !== undefined && maxCount > 1)
    ? ` #${entry.count} / ${maxCount}`
    : ''
  return <TagLabel {...style}>Entry {content} (#{entry.id})</TagLabel>
}

const ArtifactIdStyle = {
  color: 'white',
  backgroundColor: BUTTON_GREEN,
} as const satisfies CSSProperties

const TagLabelStyle = {
  fontWeight: 'normal',
  border: '1px solid gray',
  borderRadius: '0.3em',
  padding: '1px 6px',
  margin: '0 2px',
  whiteSpace: 'nowrap',
  backgroundColor: MAIN_YELLOW,
} as const satisfies CSSProperties

export const TagLabel: ReactFCC<Partial<CSSProperties>> = ({ children, ...styles }) =>
  <span style={{ ...TagLabelStyle, ...styles }}>{children}</span>

