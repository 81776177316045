import React, { CSSProperties } from 'react'
import { Array, pipe, Record as R, String as Str, Option as O } from 'effect'
import { EntryLink, Keywords, KindFigure, ReactFCC, getIconPath } from '../utils'
import { ArtifactIdTag, EntryInfo } from './EntryInfo'
import { AnnotatedSpan, annotateMatches, SearchResult, SearchResultMatchSpans } from '@fredsammons/api'
import { clsx } from 'clsx'
import { EntryProperty } from '../Artifact/utils'

const formatMatchKey = (key: string) => pipe(
  key.split('.'),
  x => x.at(-1) ?? '',
  Str.camelToSnake,
  Str.split('_'),
  Array.map(Str.capitalize),
  Array.join(' ')
)

const MatchSpan: React.FC<AnnotatedSpan> = ({ isMatch, value }) => <span key={value} className={clsx(isMatch && 'Matched')}>{value}</span>
const MatchKey: React.FC<{ label: string }> = ({ label }) =>
  <span className='MatchKey'>{formatMatchKey(label)}: </span>

const ResultScore: React.FC<{ value?: number | undefined }> = ({ value }) =>
  <></>
// value === undefined
//   ? ''
//   : <span style={{ whiteSpace: 'nowrap', color: 'green' }}> Score: {value.toFixed(4)}</span>

export const Result: React.FC<{ result: SearchResult, showScore: boolean }> = ({ result, showScore }) => {
  const { found: entry, primary, secondary } = result.item
  const { title = primary.title, shortDescription, keywords, id } = entry
  // if (date) artifactDate = date
  return (
    <li className='SearchResult'>
      <header>
        <EntryLink props={{ className: 'IconKindWrapper' }} id={id}><KindFigure entry={entry} /></EntryLink>
        <summary>
          <h2 className='articleTitle'><EntryLink id={id}>{title}</EntryLink></h2>
          <div className='labels'>
            <ArtifactIdTag entry={entry} />
            <EntryInfo entry={entry} artifact={{ primary, secondary }} />
            {showScore && <ResultScore value={result.score} />}
          </div>
          {entry.years && <dl><EntryProperty label='Year'>{entry.years}</EntryProperty></dl>}
          {shortDescription && <p className='shortDescription'>{shortDescription}</p>}
        <Keywords keywords={keywords} />
        </summary>
      </header>
      <main>
        {false && <AnnotatedMatchFields matches={result.matches ?? []} />}

      </main>
    </li>
  )
}

const AnnotatedMatchFields: React.FC<{ matches: readonly SearchResultMatchSpans[] }> = ({ matches }) => {
  const annotatedFields = pipe(
    matches,
    annotateMatches,
    R.toEntries,
    // Array.map(([label, matches]) => {
    //   matches.map((keyMatches) => {
    //     keyMatches.annotated
    //   })
    // }),
  )
  // const longestMatch = pipe(
  //   annotatedFields,
  //   Array.forEach(([label, keyMatches]) => {
  //     keyMatches.forEach(matches => {

  //     })
  //   })
  // )
  const matchedFields = Array.map(annotatedFields, ([label, matches]) =>
    <div key={label} className='ResultMatches'>
      <MatchKey label={label} />
      {matches.map(match => {
        const longest = match.annotated
          .filter(match => match.isMatch)
          .reduce((prev, current) => Math.max(prev, current.value.length ?? 0), 0)
        const x = match
          .annotated
          // .map(m => ({
          //   isMatch: m.value.length >= longest,
          //   value: m.value
          // }))
          .map(MatchSpan)
        return <span key={match.value} className='MatchKeySpans'>{x}</span>
      })
      }
    </div>
  )
  // Array.dedupe,

  return matchedFields.length > 0
    ? <div><b>Matches found in:</b> {matchedFields}</div>
    : <></>
}
