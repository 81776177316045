import React from 'react';
import { Link } from 'react-router-dom'

export const AboutFS: React.FC = () =>
  <div>
    <div className="breadcrumb menu">
      <ul>
        <li>
          <Link to="/about" className='item'>About the Archives</Link>
        </li>
        <li>
          <Link to="/about/about-fs" className='item active'>About Fred Sammons</Link>
        </li>
        <li>
          <Link to="/about/about-r2d2" className='item'>About the R<sub>2</sub>D<sub>2</sub> Center</Link>
        </li>
      </ul>
    </div>
    <div className="about">
      <h1>About Fred Sammons</h1>
      <div className="img"><img src="/img/sammons-thumb.png" alt="A photograph of Fred Sammons, founder of Fred Sammons, INC." /></div>
      <h2>FRED SAMMONS, PHD (HON), OT, FAOTA</h2>
      <p>Fred Sammons began his professional life teaching mechanical drawing to high school students. After serving in the Army
        during the Korean War, he earned his occupational therapy degree at Virginia Commonwealth University. His first job was at
        the Rehabilitation Institute of Chicago, where Fred became the director of occupational therapy in 1957. In 1960, Fred joined
        the staff of the amputee clinic at Northwestern University. This is where he began to design and build devices to assist
        individuals with disabilities in their everyday lives and activities. With a growing demand for his devices, Fred decided,
        in 1965, to devote all his time and efforts to creating, manufacturing, and distributing his inventions. At this point,
        "the rest is history." Sammons, Inc. grew to a multimillion dollar business and is now known as Sammons Preston, an AbilityOne
        Company. His honorary doctorate in occupational therapy, awarded by the Western Michigan University (WMU) Department of
        Occupational Therapy, recognizes the many contributions he has made to the industry.</p>
      <div className="img"><img src="/img/polaroidsammonssmith-thumb.png" alt="A polaroid picture of Roger O. Smith and Fred Sammons." /></div>
      <p>Dr. Sammons continues to give to the profession through grants, scholarships, and donations that support research, education,
        and clinical programs. In his retirement, he has been an active member of AMBUCS, Inc., with whom he collaborates to develop
        designs for adapted bicycles and tricycles (AmTrykes) for children with disabilities. He expresses great pride in his innovative
        seating system and the ways in which he has been able to adapt these bikes to each child’s needs. His love for innovation and
        technology that helps others has been present throughout his life and continues today.</p>
    </div>
  </div>
