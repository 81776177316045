import * as S from "@effect/schema/Schema"
import { addAnnotations } from "../addAnnotations"
import { pipe } from "effect"

export const OptionalArray = <T extends S.Schema.Any>(schema: T, identifier: string) =>
  pipe(
    S.Array(schema),
    addAnnotations({ identifier }),
    S.optional(),
    //S.optional({ default: () => [] }),
  )
