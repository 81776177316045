import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $MP3 } from "../types"

export const DescriptionKindModel = {
  code: 'F',
  name: 'Description',
  extensions: [$MP3],
  description: `Fred's Notes`,
  icon: 'icon_audio',
  alt: "Icon representing a audio file",

  plural: 'Audio Description',
  searchFields: ["Text Files"]
} as const satisfies BaseKindModel 

export const _$DescriptionKind = createArtifactKind(DescriptionKindModel)

export interface DescriptionKindEncoded extends S.Schema.Encoded<typeof _$DescriptionKind> { }
export interface DescriptionKind extends S.Schema.Type<typeof _$DescriptionKind> { }
export interface $DescriptionKind extends S.Annotable<$DescriptionKind, DescriptionKind, DescriptionKindEncoded> { }
export const $DescriptionKind: $DescriptionKind = _$DescriptionKind
