import * as S from "@effect/schema/Schema"
import { addAnnotations } from "../addAnnotations"

export const DB_ERAS = [
  "college", "career", "company", "post", "maturing", "pre", "years"
] as const

export const ERAS = [
  "Early Years", "Early Career", "College Years", "Early Company",
  "Maturing Company", "Pre-Bissel", "Post-Bissel",
] as const
export type ERAS = typeof ERAS
export type ERA = ERAS[number]

export const CHRONOLOGY = [
  "Early Years", "Early Career", "College", "Early Company",
  "Maturing Company", "Post-Bissel", "Immediately Pre-Bissel"
] as const



const _$Era = S.Literal(...ERAS)
export type EraEncoded = S.Schema.Encoded<$Era>
export type Era = S.Schema.Type<$Era>
export interface $Era extends S.Annotable<$Era, S.Schema.Type<typeof _$Era>> { }
export const $Era: $Era = _$Era.pipe(addAnnotations({ identifier: "Era" }))

export const $Eras = S.Array($Era)
export type Eras = ERA[]
  

const _$Chronology = S.Literal(...CHRONOLOGY)

export type ChronologyEncoded = S.Schema.Encoded<typeof _$Chronology>
export type Chronology = S.Schema.Type<typeof _$Chronology>
export interface $Chronology extends S.Annotable<$Chronology, Chronology, ChronologyEncoded> { }
export const $Chronology: $Chronology = _$Chronology.pipe(addAnnotations({ identifier: "Chronology" }))
