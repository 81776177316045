import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $TXT } from "../types"

export const EqTDKindModel = {
  code: 'E',
  name: 'EqTD',
  extensions: [$TXT],
  description: 'Equivalent Text Description',
  icon: 'icon_pdf',
  alt: "Icon representing an equivalent text description",
  plural: 'EqTDs',
  searchFields: ['EqTDs'],
} as const satisfies BaseKindModel 

const _$EqTDKind = createArtifactKind(EqTDKindModel)

export interface EqTDKindEncoded extends S.Schema.Encoded<typeof _$EqTDKind> { }
export interface EqTDKind extends S.Schema.Type<typeof _$EqTDKind> { }
export interface $EqTDKind extends S.Annotable<$EqTDKind, EqTDKind, EqTDKindEncoded> { }
export const $EqTDKind: $EqTDKind = _$EqTDKind
