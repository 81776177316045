import * as S from '@effect/schema/Schema'
import { $ArtifactCode, $Era, $Eras, $KindNameFromCode, $KindNames, ARTIFACT_KIND_NAMES, ERAS } from '@repo/schema'
import { pipe } from 'effect'

export const PAGE_SIZE = 25
export const $EraFromIndex = pipe(
  S.NumberFromString,
  S.int(),
  S.between(0, ERAS.length - 1),
  S.transform($Era, {
    decode: index => ERAS[index],
    encode: era => ERAS.indexOf(era)
  })
)
export const $AllOption = S.Literal('All')
const $EraRequestParam = pipe(
  S.String,
  S.compose(S.split('')),
  S.compose(S.Array($EraFromIndex), { strict: false }),
  // S.compose($ErasOrAllEras, { strict: false }),
  S.compose($Eras, { strict: false }),
  S.annotations({ identifier: "Eras Request Parameter" }),
  // S.optional({ default: () => ERAS }),
)

const $KindNameRequestParam = pipe(
  S.String,
  S.trimmed(),
  S.compose(S.split('')),
  S.compose(S.Array($ArtifactCode), { strict: false }),
  S.compose(S.Array($KindNameFromCode), { strict: false }),
  S.annotations({ identifier: "Kind Name Request Parameter" }),
  // S.compose(S.Array($ArtifactCode), { strict: false }),
  // S.optional({ default: () => ARTIFACT_KIND_NAMES }),
)

export const _$RequestParams = S.Struct({
  page: S.optional(S.NumberFromString),
  text: pipe(
    S.String,
    S.propertySignature,
    S.fromKey('q'),
  ),
  types: S.optional(S.Union($AllOption, $KindNameRequestParam)),
  eras: S.optional(S.Union($AllOption, $EraRequestParam)),
})

export const $KindsOption = S.Union($AllOption, $KindNames)
export const $ErasOption = S.Union($AllOption, $Eras)
export type KindsOption = S.Schema.Type<typeof $KindsOption>
export type ErasOption = S.Schema.Type<typeof $ErasOption>
export const $SearchParams = S.Struct({
  page: S.optional(S.Number, { default: () => 0 }),
  text: pipe(
    S.String,
    // TODO: Not sure yet how to expose these errors when encoding the parameters on the client
    // S.minLength(3),
    // S.maxLength(32)
    // S.message(() => `Your query must have at least 3 characters.`)
  ),
  types: pipe(
    $KindsOption,
    S.annotations({ identifier: 'Kind Names Parameter' }),
    S.optional({ default: () => ARTIFACT_KIND_NAMES }),
  ),
  eras: pipe(
    $ErasOption,
    S.annotations({ identifier: "Eras Parameter" }),
    S.optional({ default: () => ERAS }),
  )
})

type _$RequestParams = typeof _$RequestParams
type _$RequestParamsEncoded = S.Schema.Encoded<_$RequestParams>
export interface RequestParamsEncoded extends _$RequestParamsEncoded { }
export interface RequestParams extends S.Schema.Type<typeof _$RequestParams> { }
export interface $RequestParams extends S.Annotable<$RequestParams, RequestParams, RequestParamsEncoded> { }
export const $RequestParams: $RequestParams = _$RequestParams

export const $SearchParamsFromQuery = S.compose(
  $RequestParams,
  $SearchParams,
)
export type SearchParamsEncoded = S.Schema.Encoded<typeof $SearchParams>
export type SearchParams = S.Schema.Type<typeof $SearchParams>
export const SearchParamsFromQuery = S.decodeUnknownEither($SearchParamsFromQuery)
export const QueryFromSearchParams = S.encodeEither($SearchParamsFromQuery)
// export interface SearchParams {
//   text: string
//   types?: ReadonlyArray<ARTIFACT_KIND_NAME>
//   eras?: ReadonlyArray<ERA>
//   page?: number
// }
