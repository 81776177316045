import React from 'react'
import { FredsNotesArtifact } from "@repo/schema"
import { EntryArtifactFC } from "../types"
import { FetchError, useFetchState } from '../utils'

export const FredsNotes: EntryArtifactFC<FredsNotesArtifact> = ({ entry, artifact }) => {
  const state = useFetchState(entry)
  const { status } = state
  if (status === 'start' || status === 'loading') return <div>Loading Fred's Notes Transcript</div>
  if (status === 'error') return <FetchError message='Transcript' />
  return <>
    <div>Transcript Content</div>
    <pre>{state.data}</pre>
  </>
}
