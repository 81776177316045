import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { Result } from './Result'
import { useSearchState } from '../../hooks/useSearch'
import { ReactFCC } from '../utils'
import { MAIN_YELLOW } from '../../styles'

const MAX_DISPLAY_RESULTS = 70

export const SearchResults: React.FC = () => {
  const searchState = useSearchState()
  const { search, resultState, queryState, getNextPage, status, error } = searchState
  const location = useLocation()
  useEffect(() => { search() }, [location.search])

  const emptyQueryPhrase = queryState.phrase.value.length === 0
  const results = resultState.results
    .map((result, index) => <Result result={result} showScore={!emptyQueryPhrase} key={index} />)

  const totalResults = resultState.total

  const hasMore = results.length < totalResults
  const searchStatus = status === 'Loading'
    ? `Now searching...`
    : `Showing ${results.length} of ${totalResults} results.`

  // const artifactMatches = matchesText.length > 0
  //   ? matchesText.toString()
  //   : "No search query entered."

  const LoadMore = hasMore
    ? status === 'Loading'
      ? <LoadingButton />
      : <LoadMoreButton onPress={getNextPage} />
    : null

  const showLargeSearchWarning = totalResults > MAX_DISPLAY_RESULTS
  return (
    <div>
      <h1>{searchStatus}</h1>
      {emptyQueryPhrase && <div>No search text entered. Filtering all Artifact Entries.</div>}
      {error && <Error>{error.message}</Error>}
      {showLargeSearchWarning && <TooManyResultsWarning />}
      {results?.length !== 0 && <ol>{results}</ol>}
      {LoadMore}
    </div>
  )
}

const Error: ReactFCC = ({ children }) => <div className="error"><p>
  {children}
</p></div>

const TooManyResultsWarning = () => <div className="warning"><p>
  Your search has returned 70+ results. Refining your search may provide better results.
</p></div>

export const ResultsLoader = async ({ request }) => {
  const url = new URL(request.url)
  const q = url.searchParams.get("q")
  //const contacts = await getContacts(q)
  const contacts = null
  return { contacts, q, random: Math.random() }
}

const searchButtonStyle = {
  width: '100%',
  textAlign: 'center',
  background: MAIN_YELLOW,
  fontSize: '2em',
  padding: '0.5em',
} as const

const LoadingButton: React.FC = () =>
  <button
    disabled={true}
    style={searchButtonStyle}>Loading additional results...</button>

const LoadMoreButton: React.FC<{ onPress(): void }> = ({ onPress }) =>
  <button
    style={searchButtonStyle}
    onClick={onPress}>Load more results</button>
