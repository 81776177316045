import React from 'react'
import { R2D2 } from '../utils'
import { Link } from 'react-router-dom'

export const AccessStatement: React.FC = () =>
  <div>
    <div className="breadcrumb menu">
      <ul>
        <li><Link to="/accessibility" className='item active'>Accessibility Statement</Link></li>
        <li><Link to="/accessibility/website-eqtds" className='item'>Website EqTDs</Link></li>
      </ul>
    </div>
    <div>
      <h1>The Rehabilitation Research Design & Disability Center (<R2D2 />)
        Center Accessiblity Statement</h1>
      <p>Web accessibility is important to us. Even more, we believe in universal design so
        all users can access the site. </p>
      <p>We employ a web design team with accessibility expertise that holds deliberate accessibility
        review sessions. We welcome feedback and ideas to help make our site more accessible and usable
        for everyone.</p>
      <p><strong>Our Accessibility Features: </strong>
        The <R2D2 /> web team strives to provide a universally designed website
        that works for everyone. We have developed the site so its information is available in an
        equitable format for visitors using a wide range of access systems and strategies. Several
        of the features and procedures that we have incorporated to optimize its accessibility are
        as follows:
      </p><ul><li>
        EqTDs (Equivalent Text Descriptions) for most non-text items. These include Alt-text Brief Descriptions as well as Essential and Detailed Descriptions available on the page.
      </li><li>
          Audio introductions for all video items, coupled with one general audio description containing context of the interview for all video items.
        </li><li>
          PDFs rendered in-browser (i.e. not requiring a plugin) for use with in-browser screen readers, as well as being available for download for screen readers that work in conjunction with Adobe Reader.
        </li><li>
          Audio notes from Fred Sammons about handwritten materials.
        </li><li>
          For items that are not part of our database, essential and detailed descriptions are available via links that can be found on <Link to="/accessibility/website-eqtds">our EqTD page</Link>, which lists all of our items that have text descriptions. See <a href="EqTD_Posterette.pdf">our EqTD Posterette</a> for a quick understanding of the protocol we use and our EqTD AUDITs.
        </li></ul>
      <p>
        We attend to standards and guidelines. We test our website and frequently run checks on the accessibility of its content. While we cannot guarantee all components, we periodically evaluate our site for broken links. Accessibility is a moot point if the content isn't available to begin with.
      </p><p>
        We welcome ongoing feedback for accessibility improvement. As our website updates regularly and our project has limited resources we may miss an access feature or element. We invite all visitors to provide feedback when the site does not function as hoped - please feel free to email <a href="mailto:fredsammons-archives@uwm.edu?subject=Fred Sammons Archives Inquir
y">fredsammons-archives@uwm.edu</a> with any problems or concerns.</p>
    </div>
  </div>
