import React, { useEffect, useState } from 'react'
import { createMediaLink, ReactFCC } from '../utils'
import * as FSA from "@repo/schema"

export type FetchState =
  | { status: 'start' }
  | { status: 'loading' }
  | { status: 'ok', data: string }
  | { status: 'error', data: any }

export const useFetchState = (entry: FSA.Artifact) => {
  const [state, setState] = useState<FetchState>({ status: 'start' })

  useEffect(() => {
    setState({ status: 'loading' })
    const controller = new AbortController()

    globalThis
      .fetch(createMediaLink(entry), { signal: controller.signal })
      .then((response) => {
        response.text().then(data => setState({ status: 'ok', data }))
      })
      .catch((error) => { setState({ status: 'error', data: error }) })

    return () => { controller.abort() }
  }, [])

  return state
}


export const FetchError: React.FC<{ message?: string }> = ({ message = 'data' }) => {
  // Error: {state.data.toString()}<div><pre>{JSON.stringify(state.data, null, 2)}</pre></div>
  return <div>
    <strong>Error: Unable to load {message}</strong>
  </div>
}

export const EntryProperty: ReactFCC<{ label: string }> = ({ label, children }) =>
  <div className="EntryProperty">
    <dt>{label}:</dt>
    <dd>{children}</dd>
  </div>
