import React from 'react'
import { config } from '../../config'

export const Disclaimer = () =>
  <div>
    <h1>Disclaimer</h1>
    <p>Our tools and resources are research versions. Ethical and appropriate use of the resources found on, or linked to the Fred Sammons Archives, are the responsibilities of the user. The Fred Sammons Archives take no responsibility for their use.</p>
    <p>Please feel free to copy and distribute our resources with two conditions:</p>
    <ul>
      <li>Do NOT change the content unless you have received prior approval from the Fred Sammons Archives.</li>
      <li>Reference and cite the Fred Sammons Archives as your source.</li>
    </ul>
    <p>As we continue to improve our resources and tools we also ask for constructive feedback. This will assist in the continuing development of our website. If you have any comments, please send an email to <a href="mailto:{config.admin_email}?subject=Fred Sammons Archives Inquiry">{config.admin_email}</a>.</p>
  </div>

