import { Api } from "effect-http"
import { pipe } from 'effect'

import { AllArtifactsAPI } from './listAll/schema'
import { GetArtifactAPI } from './getArtifact/schema'
import { GetSearchResultsAPI } from './fuse/schema'
//import { MyUserApi, GetUser } from './test'

export const api = pipe(
  Api.make({ title: "Artifact API" }),
  Api.addEndpoint(GetArtifactAPI),
  Api.addEndpoint(AllArtifactsAPI),
  Api.addEndpoint(GetSearchResultsAPI),
  //Api.addEndpoint(MyUserApi),
)

export * from './listAll/schema'
export * from './getArtifact/schema'
export * from './fuse/schema'
