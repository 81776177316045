import * as S from "@effect/schema/Schema"
import { pipe } from 'effect'
import { MapNth, ValuesOf, invertRecord, keyBy, mapTuple, typeSafeObjectFromEntries } from "../utils"
import { VideoKindModel } from "./Video"
import { DocumentKindModel } from "./Document"
import { ObjectKindModel } from "./Object"
import { DescriptionKindModel } from "./Description"
import { EqTDKindModel } from "./EqTD"
import { TranscriptKindModel } from "./Transcript"
import { AudioDescriptionKindModel } from "./AudioDescription"
import { PictureKindModel } from "./Picture"
import { FredsNotesKindModel } from "./FredsNotes"

// export const KindCodeNamePairs = [
//   ['F', 'Description'] as const, // mp3
//   ['D', 'Document'] as const, // pdf 
//   ['O', 'Object'] as const, // jpg
//   ['V', 'Video'] as const, // mp4
//   ['E', 'EqTD'] as const, // txt
//   ['T', 'Transcript'] as const, // srt
//   ['U', 'AudioDescription'] as const, // mp3
//   ['P', 'Picture'] as const, // jpg
//   ['R', 'FredsNotes'] as const,
// ] as const

// { code, name } []
export const KindModels = [
  VideoKindModel,
  DocumentKindModel,
  DescriptionKindModel,
  ObjectKindModel,
  EqTDKindModel,
  TranscriptKindModel,
  AudioDescriptionKindModel,
  PictureKindModel,
  FredsNotesKindModel,
] as const
export const KindCodeNamePairs = mapTuple(KindModels, 'code', 'name')

export const KindByCode = keyBy(KindModels, 'code')
export const KindByName = keyBy(KindModels, 'name')
export type KindByName = typeof KindByName
export type KindName = keyof KindByName

export type KindCodeNamePairs = typeof KindCodeNamePairs
export const ARTIFACT_KIND_CODES = KindCodeNamePairs.map(([code]) => code) as any as MapNth<KindCodeNamePairs, 0>
export const ARTIFACT_KIND_NAMES = KindCodeNamePairs.map(([_code, name]) => name) as any as MapNth<KindCodeNamePairs, 1>

export type ARTIFACT_KIND_NAME = ValuesOf<typeof ARTIFACT_KIND_NAMES>
export type ARTIFACT_KIND_NAMES = ReadonlyArray<ARTIFACT_KIND_NAME>

export type ARTIFACT_KIND_CODE = ValuesOf<typeof ARTIFACT_KIND_CODES>
export type ARTIFACT_KIND_CODES = ReadonlyArray<ARTIFACT_KIND_CODE>
export const KindCodeNameMapping = typeSafeObjectFromEntries(KindCodeNamePairs)
export type KindCodeNameMapping = typeof KindCodeNameMapping

export const $KindName = S.Literal(...Object.values(KindCodeNameMapping))

export const $ArtifactCode = pipe(
  S.Literal(...ARTIFACT_KIND_CODES),
  S.annotations({ identifier: 'Artifact Kind Code' })
)

const reverseKindsMappings = invertRecord(KindCodeNameMapping)
export type kindNames = typeof KindCodeNameMapping[keyof typeof KindCodeNameMapping]
export const kindFromLetterCode = (kindLetter: keyof typeof KindCodeNameMapping) => KindCodeNameMapping[kindLetter]
export const letterCodeFromKind = (kindName: kindNames) => reverseKindsMappings[kindName]
export const $KindNameFromCode = S.transform(
  $ArtifactCode,
  $KindName,
  // S.Literal(...Object.values(KindCodeNameMapping)),
  {
    decode: kindFromLetterCode,
    encode: letterCodeFromKind
  })


export const $KindNames = S.Array($KindName)
