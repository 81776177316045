import { Effect, Option, pipe, Console } from 'effect'
import { Client } from "effect-http"
import { api } from '@fredsammons/api'
import { tryParseInt } from '../utils'
import { config } from '../../config'

const client = Client.make(api, { baseUrl: config.base_url() })
export const getArtifact = async (id: string | undefined) => ({
  primary: await pipe(
    tryParseInt(id),
    Option.map(id => pipe(
      client.getArtifact({ path: { id } }),
      Effect.tapError(response => Console.log('Error fetching artifact: ', response)),
      Effect.match({
        onSuccess: response => response,
        onFailure: response => response,
      }),
      Effect.runPromise
    )),
    Option.getOrElse(() => Promise.resolve({ _tag: 'BadId', message: `"${id}" is not a valid artifact id.` } as const))
  )
})
