import * as S from "@effect/schema/Schema"
import { addAnnotations } from "../addAnnotations"

export const CATEGORIES = [
  "planning", "organization", "marketing", "legal", "public_relations", "donations",
  "creation", "production", "sales", "clinical_practice", "education", "upbringing",
  "fred_notes",
] as const

const _$CategoryName = S.Literal(...CATEGORIES)

export type CategoryNameEncoded = S.Schema.Encoded<$CategoryName>
export type CategoryName = S.Schema.Type<$CategoryName>
export interface $CategoryName extends S.Annotable<$CategoryName, S.Schema.Type<typeof _$CategoryName>> { }
export const $CategoryName: $CategoryName = _$CategoryName.pipe(addAnnotations({ identifier: "Categories" }))
