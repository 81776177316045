import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $SRT, $TXT } from "../types"

export const TranscriptKindModel = {
  code: 'T',
  name: 'Transcript',
  extensions: [$TXT, $SRT],
  description: 'Video Captions',
  //description: "Icon representing a generic database item",

  icon:  'icon_pdf',
  plural: 'Transcripts',
  alt:"Icon representing a generic database item",
  searchFields: []
} as const satisfies BaseKindModel 

const _$TranscriptKind = createArtifactKind(TranscriptKindModel) 

export interface TranscriptKindEncoded extends S.Schema.Encoded<typeof _$TranscriptKind> { }
export interface TranscriptKind extends S.Schema.Type<typeof _$TranscriptKind> { }
export interface $TranscriptKind extends S.Annotable<$TranscriptKind, TranscriptKind, TranscriptKindEncoded> { }
export const $TranscriptKind: $TranscriptKind = _$TranscriptKind
