import * as S from '@effect/schema/Schema'
import type { FuseResult, FuseResultMatch, RangeTuple } from 'fuse.js'
import { $Artifact, $Artifacts, Artifact } from '@repo/schema'
import { DeepReadonly } from '../utils'

export const $RangeTuple: S.Schema<Readonly<RangeTuple>> = S.Tuple(S.Number, S.Number)

const _$SearchResultMatchSpans: S.Schema<DeepReadonly<FuseResultMatch>> = S.Struct({
  indices: S.Array($RangeTuple),
  refIndex: S.optional(S.Number, { exact: true }),
  key: S.optional(S.String, { exact: true }),
  value: S.optional(S.String, { exact: true }),
})
export interface SearchResultMatchEncoded extends S.Schema.Encoded<typeof _$SearchResultMatchSpans> { }
export interface SearchResultMatchSpans extends S.Schema.Type<typeof _$SearchResultMatchSpans> { }
export interface $SearchResultMatchSpans extends S.Annotable<$SearchResultMatchSpans, SearchResultMatchSpans, SearchResultMatchEncoded> { }
export const $SearchResultMatchSpans: $SearchResultMatchSpans = _$SearchResultMatchSpans

export type FuseSearchResult = Readonly<FuseResult<Artifact>>
// TODO: not setting the type here Because FuseResult needs to be readonly but that causes
// issue with Artifact not matching
// : S.Schema<FuseSearchResult>
export const _$SearchResult = {
  item: S.Struct({
    found: $Artifact,
    // found: S.optional(S.Record($Artifact.fields.id, S.Array($SearchResultMatchSpans)), { exact: true }),
    primary: $Artifact,
    secondary: $Artifacts,
  }),
  refIndex: S.Number,
  score: S.optional(S.Number, { exact: true }),
  matches: S.optional(S.Array($SearchResultMatchSpans), { exact: true }),
}
export const _$SearchResultFuture = {
  item: S.Struct({
    found: $Artifacts, // S.Record($Artifact.fields.id, S.Array($SearchResultMatch), { exact: true })
    primary: $Artifact,
    secondary: $Artifacts,
  }),
  refIndex: S.Number,
  score: S.optional(S.Number, { exact: true }),
  matches: S.optional(S.Array(S.Record(S.String, S.Array($SearchResultMatchSpans))), { exact: true }),
}

export class $SearchResult extends S.Class<$SearchResult>('SearchResult')(_$SearchResult, {}) {
  static readonly array = S.Array($SearchResult)
  static readonly chunk = S.Chunk($SearchResult)
}

export interface SearchResultEncoded extends S.Schema.Encoded<typeof $SearchResult> { }
export interface SearchResult extends S.Schema.Type<typeof $SearchResult> { }
// export interface $SearchResult extends S.Annotable<$SearchResult, SearchResult, SearchResultEncoded> { }
// export const $SearchResult: $SearchResult = _$SearchResult

export const _$SearchResponse = S.Struct({
  total: S.Int,
  results: $SearchResult.array
})
type _$SearchResponse = typeof _$SearchResponse
export interface SearchResponseEncoded extends S.Schema.Encoded<_$SearchResponse> { }
export interface SearchResponse extends S.Schema.Type<_$SearchResponse> { }
export interface $SearchResponse extends S.Schema<SearchResponse, SearchResponseEncoded> { }
export const $SearchResponse: $SearchResponse = _$SearchResponse
