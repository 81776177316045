import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Outlet } from 'react-router'

export const RootLayout: React.FC = () =>
  <div className="app">
    <Header />
    <div className="main"><Outlet /></div>
    <Footer />
  </div>


