import * as S from "@effect/schema/Schema"
import { pipe } from "effect"
import { addAnnotations } from "../addAnnotations"

// export const Digit = Integer.pipe(S.between(0, 9))
export const $Digit = pipe(
  S.Literal(0, 1, 2, 3, 4, 5, 6, 7, 8, 9),
  addAnnotations({ identifier: 'Digit' })
)

// 00 
// export const TimePart = S.templateLiteral(Digit, Digit)
// .pipe(addAnnotations({ identifier: 'Timepart' }))

// 00:00:00
// should be /^\d{2}:\d{2}:\d{2}$/ but at least one entry is: 0:00:00
// export const Duration = S.templateLiteral(TimePart, S.literal(':'), TimePart, S.literal(':'), TimePart)
export const $Duration = pipe(
  S.String,
  S.pattern(/^\d\d?:\d{2}:\d{2}$/),
  addAnnotations({ identifier: 'Clip-Duration' }),
)

// export const Year = S.templateLiteral(Digit, Digit, Digit, Digit)
export const $Year = pipe(
  S.String,
  S.pattern(/^\d{4}$/),
  addAnnotations({ identifier: 'Year' })
)

// #### | ####-####   // From Date
export const $YearRange = pipe(
  S.String,
  S.pattern(/^\d{4}-\d{4}$/),
)
// export const Years = S.templateLiteral(Year, S.literal('-'), Year)
export const $Years = pipe(
  S.Union($Year, $YearRange),
  addAnnotations({ identifier: 'Years' })
)
