import React, { Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { Array, Record, Struct, pipe, Either, Option } from 'effect'
import { useSubmit } from 'react-router-dom'

import { QueryFromSearchParams, SearchParams, SearchParamsFromQuery } from '@fredsammons/api'
import { ERAS, KindByName, KindName, SEARCH_FIELDS, SearchField } from '@repo/schema'
import { TogglerState, useToggleField } from '../components/Toggler'
import { ReactFCC } from '../components/utils'
import { tap } from '../utils'

export interface SearchQueryState {
  phrase: {
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
  }
  searchFields: ReadonlyArray<SearchField>
  types: TogglerState<KindName[]>
  eras: TogglerState<ERAS>
  setPage: Dispatch<SetStateAction<number>>
  page: number
  query: SearchParams
  getEncodedQuery(queryState?: Partial<SearchQueryState['query']>): ReturnType<typeof QueryFromSearchParams>
}

const SearchQueryStateContext = createContext<SearchQueryState>({
  phrase: { value: '', setValue() { } },
  page: 0, setPage() { },
} as any as SearchQueryState)

export const useQueryState = () => useContext(SearchQueryStateContext)

const DEFAULT_QUERY_PARAMS = {
  page: 0 as const,
  types: [] as const,
  text: '' as const,
  eras: [] as const,
} as const
const getQueryParams = (): SearchParams =>
  pipe(
    globalThis.location.search.length === 0
      ? Option.none()
      : Option.some(globalThis.location.search),
    Option.map((search) => pipe(
      new URLSearchParams(search).entries(),
      Object.fromEntries,
      SearchParamsFromQuery,
      Either.getOrElse((error): SearchParams => {
        console.warn('Invalid query parameters. Falling back to default')
        console.warn(error)
        return DEFAULT_QUERY_PARAMS
      }),
    )),
    Option.getOrElse(() => DEFAULT_QUERY_PARAMS)
    // console.log('Starting with params:', queryParams)
  )

export const QueryStateProvider: ReactFCC = ({ children }) => {
  const queryParams = useMemo(() => getQueryParams(), [])

  // Ignoring queryParams.page because refreshing navigating to the results screen
  // with a `page` number already set would cause the search to skip any preceeding pages.
  // Need Either :
  // 1. Use a different URL per page for the paging mechanism, instead of infinite scroll
  // 2. Add a button to load previous results
  // 3. Load all result pages from 0 to `page` on load
  const [page, setPage] = useState(0)
  // const setPage = useCallback((page: number) => {
  //   _setPage(page)
  //   globalThis.location.search
  // }, [])
  const [phrase, setPhrase] = useState(queryParams.text)
  const typeNames = Record.keys(KindByName)
  const types = useToggleField(typeNames,
    queryParams.types === 'All'
      ? { initialAllSet: true  }
      : { initial: queryParams.types }
  )

  const eras = useToggleField(ERAS, queryParams.eras === 'All'
    ? { initialAllSet: true }
    : { initial: queryParams.eras })

  const searchFields = useMemo(() => pipe(
    KindByName,
    Struct.pick(...types.selected),
    Record.values,
    Array.flatMap(type => type.searchFields),
    typeFields => SEARCH_FIELDS.filter(field => typeFields.includes(field))
  ), [types.selected])

  const query = useMemo(() => ({
    page,
    text: phrase,
    types: types.allSet ? ('All' as const) : types.selected,
    eras: eras.allSet ? ('All' as const) : eras.selected, // eras.allSet ? undefined : eras.selected,
  }) as const, [page, phrase, eras.selected, types.selected])

  const getEncodedQuery = useCallback((update: Partial<SearchQueryState['query']> = {}) => {
    return QueryFromSearchParams({ ...query, ...update })
  }, [query, query.types, query.eras, phrase, page])

  const state = {
    searchFields,
    eras, types,
    page, setPage,
    phrase: { value: phrase, setValue: setPhrase } as const,
    getEncodedQuery,
    query,
  } as const satisfies SearchQueryState

  return <SearchQueryStateContext.Provider value={state}>
    {children}
  </SearchQueryStateContext.Provider>
}

export const useQuerySubmitter = (queryState: () => SearchQueryState) => {
  const submit = useSubmit()
  const handleSubmit: React.FormEventHandler = useCallback(
    (event) => pipe(
      queryState().getEncodedQuery({ page: 0 }),
      tap(() => event.preventDefault()),
      Either.map(
        query => pipe(
          query,
          Object.entries,
          params => new URLSearchParams(params),
          params => submit(params, { action: '/search/results/' })
        )
      )
    ),
    [submit, queryState]
  )
  return handleSubmit
}

export const updateSearchQuery = (queryState: SearchQueryState, updates: Partial<SearchQueryState['query']> = {}) => {
  //(event) =>
  return pipe(
    queryState.getEncodedQuery(updates),
    // tap(() => event.preventDefault()),
    Either.map(
      query => pipe(
        query,
        Object.entries,
        params => new URLSearchParams(params),
        params => {
          const url = new URL(globalThis.location.href)
          // url.searchParams.set(param, value)
          url.search = params.toString()
          // Location<any> = params.toString()
          globalThis.history.pushState(queryState['query'], '', '?' + params.toString())
        }
      )
    )
  )
  // ,
  //   [queryState]
  // )
}

// Maximum size (in chars) of a completable search query.
// setState({
//   searchPhrase: event.target.value
//     .replace(/[#%^(){}<>:;`]/g, "")
//     .substring(0, maxSearchQuerySize)
// })
