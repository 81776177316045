import * as S from "@effect/schema/Schema"
import { ParseResult as PR, } from "@effect/schema"
import { $Kinds } from "./kinds"
import { $Chronology, $Years } from "./types"
import { addAnnotations } from './addAnnotations'
import { pipe } from "effect"
import { OptionalArray } from "./types/utils"
import * as Record from "effect/Record"

export const OptionalInt = pipe(S.Int, S.optional())
export const getArtifactPretty = ({ actual }: PR.ParseIssue) => {
  if (S.is(S.Struct({ id: S.String }))(actual)) return `Artifact (${actual.id})`
}
export const artifactParseIssueTitle = { parseIssueTitle: getArtifactPretty } as const

const ArtifactId = S.Int.pipe(addAnnotations({ identifier: 'ArtifactId' }))
export const _$ArtifactModel = {
  row: S.Int.pipe(addAnnotations({ identifier: 'csv-row' })),
  // TODO: change to something that helps differentiate what an artifactId is from
  // what the entries about said artifact
  // ex: dataId, entryId, uid ?
  id: S.Int.pipe(addAnnotations({ identifier: 'id' })),
  artifactId: ArtifactId,

  primaryId: S.Int.pipe(addAnnotations({ identifier: 'Primary-artifact-id' })),
  isPrimary: S.Boolean.pipe(addAnnotations({ identifier: 'is-primary-id' })),

  kind: $Kinds,
  
  enabled: S.Boolean,
  box: OptionalInt,
  count: OptionalInt,
  // count: Int.pipe(S.optional({ default: () => 1 })),

  title: S.String.pipe(addAnnotations({ identifier: 'Title' }), S.optional()),
  shortDescription: S.String.pipe(addAnnotations({ identifier: 'shortDescription' }), S.optional()),

  years: pipe(
    $Years,
    addAnnotations({ identifier: 'Artifact years' }),
    S.optional(),
  ),

  // referencedBy: Integer,
  // era: $Eras,
  //subArtifactIds: OptionalArray(ArtifactId, 'SubArtifactIds'),
  chronology: S.optional($Chronology),
  keywords: OptionalArray(S.String, 'Keywords'),
  relatedArtifacts: OptionalArray(S.Number.pipe(S.int()), 'RelatedArtifacts'),
}

// .pipe(addAnnotations({
//   identifier: 'Artifact',
//   pretty: () => v => `(${v.id})${v.kind.name} ArtifactId: ${v.artifactId}`,
//    ...artifactParseIssueTitle
// }))

export class $Artifact extends S.Class<$Artifact>('Artifact')(_$ArtifactModel, {
  //pretty: () => v => `(${v.id})${v.kind.name} ArtifactId: ${v.artifactId}`,
  ...artifactParseIssueTitle
}) {
  public readonly _tag: 'Artifact' = 'Artifact'
  
  static readonly array = S.Array($Artifact)
  static readonly chunk = S.Chunk($Artifact)
}

export interface ArtifactEncoded extends S.Schema.Encoded<typeof $Artifact> { }
export interface Artifact extends S.Schema.Type<typeof $Artifact> { }

// export interface $Artifact extends S.Annotable<$Artifact, Artifact, ArtifactEncoded> { }
// export const $Artifact: $Artifact = _$Artifact

export const $Artifacts = S.Array($Artifact)
export type $Artifacts = typeof $Artifacts
export type Artifacts = S.Schema.Type<$Artifacts>
export type ArtifactsEncoded = S.Schema.Encoded<$Artifacts>

// export interface AnnotableFrom<
//   Self extends S.Schema<A, I, R>,
//   Base extends S.Schema.Any, A = S.Schema.Type<Base>, I = S.Schema.Encoded<Base>, R = S.Schema.Context<Base>
// > extends S.Schema<A, I, R> {
//   annotations(annotations: S.Annotations.Schema<A>): Self
// }
