import React from 'react'
import { PictureArtifact } from "@repo/schema"
import { EntryArtifactFC } from "../types"
import { createMediaLink } from "../../utils"
import { Categories } from '../sections'

const previewOptions = { count: 0, extension: '.png' } as const
// Expected file type: 1.pdf (no counts)
// mediaEmbed: <div className="imageThumbnail"><img src={mediaLink} alt={picture.shortDescription} /></div>,

export const Picture: EntryArtifactFC<PictureArtifact> = ({ entry, artifact }) =>
  <>
    <img src={createMediaLink(entry, previewOptions)} alt={entry.shortDescription} />
    <dl><Categories entry={entry} /></dl>
  </>
