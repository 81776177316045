import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $MP3 } from "../types"

export const AudioDescriptionKindModel = {
  code: 'U',
  name: 'AudioDescription',
  extensions: [$MP3],
  description: 'Audio Introduction',
  icon: 'icon_audio',
  alt: "An icon that represents audio in the Fred Sammons Archive database.",

  plural: 'Audio',
  searchFields: ["Text Files"]
} as const satisfies BaseKindModel 

export const _$AudioDescriptionKind = createArtifactKind(AudioDescriptionKindModel)

export interface AudioDescriptionKindEncoded extends S.Schema.Encoded<typeof _$AudioDescriptionKind> { }
export interface AudioDescriptionKind extends S.Schema.Type<typeof _$AudioDescriptionKind> { }
export interface $AudioDescriptionKind extends S.Annotable<
  $AudioDescriptionKind,
  AudioDescriptionKind,
  AudioDescriptionKindEncoded
> { }

export const $AudioDescriptionKind: $AudioDescriptionKind = _$AudioDescriptionKind
