import { useCallback, useState } from 'react'
import { SearchResponse, SearchResult } from '@fredsammons/api'

export type SearchResults = ReadonlyArray<SearchResult>
export const useSearchResults = (): SearchResultAPI => {
  // const [artifacts, setArtifacts] = useState<SearchResult[] | undefined>(undefined);
  const [total, setTotal] = useState<number>(0)
  const [results, setResults] = useState<SearchResults>([])

  const clear = useCallback(() => setResults([]), [])
  const setResponse = useCallback(
    (response: SearchResponse) => {
      setTotal(response.total)
      setResults(response.results)
    },
    []
  )
  const append = useCallback(
    (response: SearchResponse) => setResults(prevState => [...prevState, ...response.results]),
    [],
  ) // prevState => [ ...prevState, ...response.results ]
  return { total, results, clear, append, setResponse } as const
}

export interface SearchResultAPI {
  total: number,
  results: SearchResults,
  clear(): void
  append(state: SearchResponse): void
  setResponse(state: SearchResponse): void
}
