import React, { Component } from 'react'
import { R2D2 } from '../utils'
import { Brief, Detailed, EqTD, Essential } from './EqTD'
import { Link } from 'react-router-dom'

export const WebsiteEqTDs: React.FC = () =>
    <div>
      <div className="breadcrumb-menu">
        <ul>
          <li><Link to="/accessibility" className='item'>Accessibility Statement</Link></li>
          <li><Link to="/accessibility/website-eqtds" className='item active'>Website EqTDs</Link></li>
        </ul>
      </div>
      <div>
        <h1>Equivalent Text Descriptions</h1>
        <p>Universally designed documents, slides, videos, etc require additional preparation to make
          them accessible to all individuals. Equivalent text descriptions (EqTD’s) provide written
          descriptions of graphic elements. While it is typical to think of this process as an accommodation
          for blind individuals, EqTD’s help individuals with low vision, cognitive or perceptual limitations
          as well as the student who does not speak English as a primary language, or who may not have
          completely understood the purpose of a graphic in a lecture.</p>
        <p>The location of EqTD’s may vary. On this page it is demonstrated that EqTD’s may be placed in
          a separate location with a copy of the graphic, a method that allows for bi-modal learning.</p>
        <h2>Website Equivalent Text Descriptions</h2>
        <table><tbody>
          <EqTD url="/img/r2d2_logo.png" brief="Rehabilitation Research Design and Disability (R2D2) Center Logo.">
            <Brief>Rehabilitation Research Design and Disability (<R2D2 />) Center Logo.</Brief>
            <Essential>A logo representing the Rehabiliation Research Design and Disability (<R2D2 />) Center.</Essential>
            <Detailed> A black rounded cornered square containing the stylized letters and numbers '<R2D2 />' in bold serif font. The 2's in the name are subscripts. The word 'center' is italicized and is below <R2D2 />.The black cornered square is surrounded by a white boarder and then an additional black shadow has been added to give the logo a 3D apperance.</Detailed>
          </EqTD>
          <EqTD url="/img/uwm_eqtd.png" brief="University of Wisconsin-Milwaukee Logo.">
            <Essential>A logo representing the University of Wisconsin-Milwaukee.</Essential>
            <Detailed>A stylized UWM in white lettering. Below the letters are 2 wavey yellow ribbons that span the entire logo.</Detailed>
          </EqTD>

          <EqTD url="/img/FSA_logo_eqtd.png" brief="Fred Sammons Archives Logo.">
            <Essential>A logo representing the Fred Sammons Archive Website.</Essential>
            <Detailed>The words 'The Fred Sammons Archives'are capital white and gray sans-serif font letters.  The 'O' in Sammons and the 'I' in Archives are parts of a button hook. The 'O' is the button surrounded by the top of the button hook and the 'I'is the button hook handle.</Detailed>
          </EqTD>
                 <EqTD url="/img/icons/icon_pdf.png" brief="Document Icon">
            <Essential>An icon that represents documents in the Fred Sammons Archive database.</Essential>
            <Detailed>A portrait rectangle with three horizontal black lines of varying length inside of it, representing a document.  In the upper right corner, a small rounded black triangle is visible, indicative of the page being able to turn.</Detailed>
          </EqTD>
                 <EqTD url="/img/icons/icon_images.png" brief="Image Icon.">
            <Essential>An icon that represents images in the Fred Sammons Archive database.</Essential>
            <Detailed>Two rectangles overlapping, the one in the foreground having a stylized landscape on it.  Only the edges of the latter are visisble.</Detailed>
          </EqTD>
          <EqTD url="/img/icons/icon_video.png" brief="Video Icon">
            <Essential>An icon that represents videos in the Fred Sammons Archive database.</Essential>
            <Detailed>An image of a section of a film strip, dark with white dashes at the top and bottom. A triangle representing a play button is embedded in the center.</Detailed>
          </EqTD>
          <EqTD url="/img/icons/icon_object.png" brief="Artifact/Object Icon">
            <Essential>An icon that represents physical objects in the Fred Sammons Archive database.</Essential>
            <Detailed>Image of a cube, sphere and cone touching each other.The cube is in the front most postion followed by the cone to the right and the sphere in the background. The items are shaded to indicate that they are 3-dimensional.</Detailed>
          </EqTD>
          <EqTD url="/img/icons/icon_audio.png" brief="Audio Icon">
            <Essential>An icon that represents audio descriptions in the Fred Sammons Archive database.</Essential>
            <Detailed>Image of a stylized speaker, the widest end facing the right. Various lengthed curved lines are emitting from the right side of the speaker and represent sound waves.</Detailed>
          </EqTD>
          <EqTD url="/img/icons/icon_generic.png" brief="Generic Database Object Icon">
            <Essential>An icon that represents items in the Fred Sammons Archive database that do not belong in any other catgory. An example of this is equivalent text descriptions (EqTDs)</Essential>
            <Detailed>Image of a rectangluar box, with two flaps, shaded to look three-dimensional.  Several rectangular items are visible from the top of the box that indicate there are objects in the Fred Sammons Archives.</Detailed>
          </EqTD>
          <EqTD url="/img/sammons-thumb.png" brief="A Photograph of Fred Sammons.">
            {/*"/img/sammons.png"*/}
            <Essential>A photograph of Fred Sammons, founder of Fred Sammons, INC. All of the archival items in the Fred Sammons Archive Website are from his past business and personal endeavors.</Essential>
            <Detailed>A photograph of Fred Sammons, an older gentleman with white hair. He is wearing a black suit, a white collared button up shirt, and a black bowtie. He is smiling and facing toward the camera.</Detailed>
          </EqTD>
          <EqTD url="/img/currentsammonssmith-thumb.png" brief="A Photograph of Roger Smith and Fred Sammons.">
            <Essential>
              A photograph of Roger Smith and Fred Sammons at the American Occupational Therapy Association Conference. Roger Smith is director of the <R2D2 /> Center at University of Wisconsin-Milwaukee.
            </Essential>
            <Detailed>
              Image of two men, Roger Smith and Fred Sammons, standing next to each other. Roger Smith is on the left and Fred Sammons is on the right. Roger appears to be several inches taller than Fred in the photograph. Roger has grey hair, glasses, and is a wearing a black suit with a yellow button up shirt and black tie. Roger is also wearing a lanyard around his neck.
              On the right, Fred has white hair and is wearing a black suit with a white collared button up shirt and a black bowtie. On his suit lapels, Fred is wearing two occupational therapy pins and a conference name tag with ribbons underneath it to signify his position and roles during conference. Both men are smiling and are standing in a large room at a conference center.
            </Detailed>
          </EqTD>
          <EqTD url="/img/r2d2team-thumb.png" brief="A Photgraph of the Staff of the Rehabiliation, Research, Design, and Disability (R2D2) Center.">
            {/*"/img/r2d2team.png"*/}
            <Essential>A photograph of the <R2D2 /> Center Staff at University of Wisconsin-Milwaukee. The <R2D2 /> Center staff created and developed the Fred Sammons Archive Website.</Essential>
            <Detailed>Image of a variety of students and staff smiling outside on a sunny day. They are arranged in 3 tiered rows with 4 people standing in the back, 3 people sitting in the middle, and 4 people sitting in the front.  On the far right side, in the 3rd row in the back, the director of the center, Dr. Roger Smith, is standing.</Detailed>
          </EqTD>
          <EqTD url="/img/polaroidsammonssmith-thumb.png" brief="A polaroid picture of Roger O. Smith and Fred Sammons." >
            {/*"/img/polaroidsammonssmith-thumb.png"*/}
            <Essential>A polaroid picture circa April 2007. Roger and Fred were attending the 90th American Occupational Therapy Association Conference.</Essential>
            <Detailed>A picture of two men standing side by side. The one to the left is Roger Smith. He has dark gray hair, wire rimmed glasses and a dark gray mustache. He is wearing a black suit with a blue collared button up shirt and black tie. The man on the right is Fred Sammons. He is shorter than Roger and has white hair and wire rimed glasses. Fred is wearing a black tuxedo with a red and black vest, a white collared button up shirt and black bowtie. He also has 2 golden pins on his left jacket lapel. Fred is also wearing an American Occupational Therapy Association Conference name badge with a red ribbon hangin below it. The background of the picture is of black cloth and a poster for Sammons Preston. The photo is surrounded by a white boarder with a large border at the bottom. At the bottom, in blue ink, is written "Roger and Fred April 2007".</Detailed>
          </EqTD>
        </tbody>
        </table>
        <p>Please note that database artifacts have equivalent text descriptions included in their pages.</p>
      </div>
    </div>


