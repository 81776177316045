import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $JPG } from "../types"

export const ObjectKindModel = {
  code: 'O',
  name: 'Object',
  extensions: [$JPG],
  description: "Object",
  //description: "Photos of Archive Objects",

  icon: 'icon_object',
  plural: 'Objects',
  alt: "An icon that represents physical objects in the Fred Sammons Archive database.",
  searchFields: ["Titles & Keywords", "Descriptions", "EqTDs"]
} as const satisfies BaseKindModel 

const _$ObjectKind = createArtifactKind(ObjectKindModel)

export interface ObjectKindEncoded extends S.Schema.Encoded<typeof _$ObjectKind> { }
export interface ObjectKind extends S.Schema.Type<typeof _$ObjectKind> { }
export interface $ObjectKind extends S.Annotable<$ObjectKind, ObjectKind, ObjectKindEncoded> { }
export const $ObjectKind: $ObjectKind = _$ObjectKind
