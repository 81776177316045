import React from 'react'
import { DescriptionArtifact } from "@repo/schema"
import { ArtifactFC, EntryArtifactFC, EntryFC } from "../types"
import { Mp3 } from "../../Mp3"

// const transcriptLink = createMediaLink(artifact, { extension: '.txt' })
// downloadLinkEmbed: <div className="downloadButton"><a href={transcriptLink}>Download Transcript {count}of Audio</a></div>
export const Description: EntryArtifactFC<DescriptionArtifact> = ({ entry, artifact }) =>
  <Mp3 id={`fredNote-${entry.count}`} entry={entry} />

