import React from 'react'
import { ERAS } from '@repo/schema'
import { PickerFC } from './utils'
import { CheckboxPickerAll, CheckboxPickerChoices, TogglePicker, TogglePickerAll } from './Picker'

// Home
export const CheckboxEras: PickerFC<ERAS> =
  ({ picker }) =>
    <fieldset className="eras toggler">
      <legend>Fred's Eras</legend>
      <CheckboxPickerAll picker={picker} label="All Eras" />

      <div className="seperator" />

      <CheckboxPickerChoices picker={picker} />
    </fieldset>


// sidebar
export const TogglerEras: PickerFC<ERAS> =
  ({ picker, label }) =>
    <fieldset className="eras toggler">
      <legend>Searching These {label}:</legend>
      <div className="toggler-choices">
        <TogglePickerAll picker={picker} label="All Eras" />

        {picker.choices
          .map(choice =>
            <TogglePicker key={choice} choice={{name: choice, value: choice}} picker={picker} />)}
      </div>
    </fieldset>


