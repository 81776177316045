import React from 'react'
import { Record, pipe } from 'effect'
import { clsx } from 'clsx'

import { KindByName, KindName } from '@repo/schema'
import { PickerChoiceFC, PickerFC, PickerItemFC } from './utils'
import { getIconPath } from '../utils'
import { TogglePicker, TogglePickerAll } from './Picker'

// interface SearchableType {
//   name: string
//   icon: string
//   types: ReadonlyArray<keyof KindByName>
// }
// export const TypeSearchMap: ReadonlyArray<SearchableType> = [
//   { name: 'Images', icon: '', types: ['Picture'] }, // code: 'P', fields 0,1,5
//   { name: 'Documents', icon: '', types: ['Document'] }, // code: D, fields 0,1,3,4,5
//   { name: 'Audio', icon: '', types: ['AudioDescription', 'Description', 'FredsNotes'] }, // code: UF, fields: 4
//   { name: 'Video', icon: '', types: ['Video'] }, // code: V, fields: 0,1,2
//   { name: 'Objects', icon: '', types: ['Object'] }, // code: O, fields 0,1,5
// ] as const


const TypeSearchMap: [string, keyof KindByName][] = [
  ['Images', 'Picture'],
  ['Documents', 'Document'],
  ['Audio', 'AudioDescription'],
  ['Video', 'Video'],
  ['Objects', 'Object'],
] as const

const FIELDS = [
  "Titles & Keywords",
  "Descriptions",
  "Captions",
  "Fred's Notes",
  "Text Files",
  "EqTDs",
]

const TypePickerItems: PickerItemFC<ReadonlyArray<KindName>> = ({ picker, Renderer }) =>
  <>{
    TypeSearchMap.map(
      ([label, typeName]) =>
        <Renderer
          key={label}
          picker={picker}
          choice={{
            name: label,
            value: typeName,
            alttext: KindByName[typeName].alt,
            image: KindByName[typeName].icon
          }} />
    )
  }</>

export const TypePicker: PickerFC<ReadonlyArray<KindName>> = ({ picker }) => {
  return <fieldset className="types toggler">
    <div className='toggler-label'>
      <legend>Artifact Types</legend>
      <IconPickerItem
        key='AllTypes'
        picker={picker}
        className="all-choice"
        choice={{
          value: picker.AllOption,
          name: 'All Types',
          image: 'icon_all',
          alttext: "An icon that represents all types of items in the archives."
        }} />
    </div>
    <div className="seperator filetype"></div>
    <div className="toggler-choices">
      <TypePickerItems picker={picker} Renderer={IconPickerItem} />
    </div>
  </fieldset>
}

export const IconPickerItem: PickerChoiceFC<ReadonlyArray<any>> = ({ picker, choice, className = '' }) => {
  const altText = choice.alttext ?? `An Icon representing the "${choice.name ?? choice.value}" option.`
  return <label
    className={clsx('choice', 'icon-choice', className, { highlight: picker.isChecked(choice.value) })} >
    <img className="type-icon" src={getIconPath(choice.image)} alt={altText} />
    <input style={{ display: 'none' }}
      type="checkbox" role="checkbox"
      checked={picker.isChecked(choice.value)}
      aria-checked={picker.isChecked(choice.value)}
      tabIndex={0}
      onChange={() => picker.toggle(choice.value)} />
    <span className="choice-label">{choice.name ?? choice.value}</span>
  </label>
}

// Sidebar
export const TogglerTypePicker: PickerFC<KindName[]> = ({ picker, label }) => (
  <fieldset className="types toggler">
    <legend>Searching These {label}:</legend>
    <div className="toggler-choices">
      <TogglePickerAll picker={picker} label="All Artifact Types" />
      <TypePickerItems picker={picker} Renderer={TogglePicker} />
    </div>
  </fieldset>
)


// title
// icon
// picker

// export const AllTypesOption: React.FC<{ types: SearchParamAPI['types'] }> = ({ types }) =>
//   <>
//     <label className={clsx('icon', { Highlight: types.allSet })}>
//       <img src={getIconPath('icon_all')} alt="An icon that represents all types of items in the archives." />
//       <input style={{ display: 'none' }}
//         type="checkbox"
//         role="checkbox"
//         aria-checked={types.allSet}
//         tabIndex={0}
//         checked={types.allSet}
//         onChange={types.toggleAll} />
//       <h2><small>All Types</small></h2>
//     </label>
//   </>

// const toggleOptions = pipe(
//   KindByName,
//   Record.map(
//     ({ name, alt, icon }) =>
//       <ArtifactTypeChoice
//         key={name}
//         picker={picker}
//         choice={{ value: name, alttext: alt, image: icon }} />
//   ),
//   Record.values
// )


// const toggleOptions = TypeSearchMap.map(
//   ([label, typeName]) =>
//     <ArtifactTypeChoice
//       key={label}
//       picker={picker}
//       choice={{ name: label, value: typeName, alttext: KindByName[typeName].alt, image: KindByName[typeName].icon }} />
// )
