import { Array, Option, pipe } from 'effect'
import React, { CSSProperties } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { Artifact, KindByName } from "@repo/schema"

import { BUTTON_GREEN, } from '../styles'
import { EntryFC } from './Artifact/types'
import { config } from "../config"
import { TagLabel } from './Search/EntryInfo'

export type TODO = any

export const R2D2 = () => <span>R<sub>2</sub>D<sub>2</sub></span>
export type ReactFCC<Props = {}> = React.FC<React.PropsWithChildren<Props>>

export const EntryLink: ReactFCC<{ id: number, props?: Partial<React.ComponentProps<typeof Link>> }> = ({ id, props = {}, children }) =>
  <Link to={`/artifacts/${id}`} {...props}>{children}</Link>

// TODO: add to schemas
type MediaExtensions = '.png' | '.jpg' | '.pdf' | '.mp3' | '.txt' | '.srt' | '.mp4'
const formatFileName: { [k in MediaExtensions]: (artifact: Artifact) => string } = {
  '.png': ({ artifactId, count }) => `${artifactId}-${count}.png`,
  '.jpg': ({ artifactId, count }) => `${artifactId}.${count}.jpg`,
  '.pdf': ({ artifactId }) => `${artifactId}.pdf`,
  '.mp3': ({ artifactId }) => `${artifactId}.mp3`,
  '.txt': ({ artifactId }) => `${artifactId}.txt`,
  '.srt': ({ artifactId }) => `${artifactId}.srt`,
  '.mp4': ({ artifactId }) => `${artifactId}.mp4`,
} as const

/**
  * Creates a media link for an artifact.
  *
  * @param artifact - The artifact to create a media link for.
  * @param options - The options to use when creating the media link.
  */
export const createMediaLink = (
  artifact: Artifact,
  options: MediaLinkOptions = {},
): string => {
  const count = options.count ?? artifact.count
  const extension = options.extension ?? artifact.kind.extension
  const fileName = formatFileName[extension]({ ...artifact, count })
  return `${config.media_folder}/${fileName}`
}

export const getIconPath = (name: string) => `/img/icons/${name}.png`
export interface MediaLinkOptions {
  count?: number | undefined | null
  extension?: MediaExtensions
  id?: string | number
}

export const tryParseInt = (maybeInt?: string) => {
  if (typeof maybeInt !== 'string') return Option.none()
  try {
    return Option.some(parseInt(maybeInt))
  } catch (e) {
    return Option.none()
  }
}

export const Keyword: ReactFCC = ({ children }) =>
  <li className='keyword'>
    <TagLabel backgroundColor='lightyellow' borderColor='lightgray'>{children}</TagLabel>
  </li>

export const Keywords: React.FC<{ keywords?: ReadonlyArray<string> | undefined }> = ({ keywords }) =>
  !!keywords && <div>
    <ul className="keywords">
      <li style={{ listStyleType: 'none' }}><strong>Keywords:</strong></li>
      {cleanStringArray(keywords).map(keyword => <Keyword key={keyword}>{keyword}</Keyword>)}
    </ul>
  </div>

// <ul className="keywords">{Array.dedupe(keywords).map(keyword => <Keyword key={keyword}>{keyword}</Keyword>)}</ul>

export const SearchFields: React.FC<{ fields: ReadonlyArray<string> }> = ({ fields }) =>
  <div className="Search-fields">
    <h3>Searching These Fields:</h3>
    <ul>{cleanStringArray(fields).map(field => <li key={field} className="Search-field">{field}</li>)}</ul>
  </div>
//     <ul>{Array.dedupe(fields).map(field => <li key={field} className="Search-field">{field}</li>)}</ul>

// export const FieldPicker: React.FC<Pick<SearchQueryState, 'types'>> = ({ fields }) =>

export const cleanStrings = (strings: string[]) =>
  strings
    .map(str => str.trim())
    .filter(x => !!x)

export const cleanStringArray = (strings: readonly string[]) =>
  pipe(
    strings,
    Array.map(topic => cleanStrings(topic.split('_')).join(' ')),
    cleanStrings,
    Array.dedupe,
  )

// const KindFormatStyle = {
//   fontWeight: 'bold',
//   fontSize: 'large',
//   padding: '1px 6px',
//   color: 'white',
//   backgroundColor: BUTTON_GREEN,
// } as const satisfies CSSProperties

// export const KindLabel: EntryFC = ({ entry }) => <TagLabel {...KindFormatStyle}>{KindByName[entry.kind.name]['description']}</TagLabel>

// <figcaption>{shortDescription}</figcaption>
export const Icon: React.FC<{ alt: string, name: string }> = ({ alt, name }) => <img src={getIconPath(name)} alt={alt} />

export const KindFigure: EntryFC = ({ entry }) =>
  <div className='kindIcon'>
    <Icon name={KindByName[entry.kind.name].icon} alt={KindByName[entry.kind.name].alt} />
    <div className='kindName'>{KindByName[entry.kind.name].description}</div>
  </div>
