import * as S from "@effect/schema/Schema"
import { $FileName } from "./Filename"
import { $YoutubeLink } from "./Youtube"
import { pipe } from "effect"
import { addAnnotations } from "../addAnnotations"

export const $MediaLink = S.Union($YoutubeLink, $FileName)

// V2.04, etc.
// export const Clip = S.templateLiteral(S.literal('V'), Integer, S.literal('.'), Digit, Digit)
// TODO: address errors in spreadsheet
export const $ClipPatterns = S.Union(
  S.String.pipe(S.pattern(/^V\d+\.\d+$/)), // V#+.#+
  S.String.pipe(S.pattern(/^V\d+\.\d+.\d+$/)), // V#+.#+.#+
  S.String.pipe(S.pattern(/^V\.\d+.\d+$/)), // V.#+.#+
)

export const $Clip = pipe(
  //export const Clip = S.templateLiteral(S.literal('V'), y)
  $ClipPatterns,
  addAnnotations({ identifier: 'Clip#' }),
)

