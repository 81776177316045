import { cleanStringArray, ReactFCC } from '../../utils'
import { EntryProperty } from '../utils'

export const Topic: ReactFCC = ({ children }) => <li className='keyword'>{children}</li>

export const Topics: React.FC<{ topics?: ReadonlyArray<string> | undefined }> = ({ topics = [] }) =>
  topics.length > 0 && <EntryProperty label="Topics">
    <ul className="keywords">
      {cleanStringArray(topics).map(topic => <Topic key={topic}>{topic}</Topic>)}
    </ul>
  </EntryProperty>
