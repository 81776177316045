import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $CategoryName, $Format, $PDF, $Topic } from "../types"
import { addAnnotations } from "../addAnnotations"
import { OptionalArray } from "../types/utils"

export const DocumentKindModel = {
  code: 'D',
  name: 'Document',
  extensions: [$PDF],
  description: 'Document',
  plural: 'Documents',

  icon: 'icon_pdf',
  alt:"An icon that represents documents in the Fred Sammons Archive database.",
  searchFields: ["Titles & Keywords", "Descriptions", "Fred's Notes", "Text Files", "EqTDs"]
} as const satisfies BaseKindModel 

export const _$DocumentKind = createArtifactKind(DocumentKindModel)
  .pipe(S.extend(S.Struct({
    formats: OptionalArray($Format, 'Formats'),
    categories: OptionalArray($CategoryName, 'Categories'),
    topics: OptionalArray($Topic, 'Topics'),

    //originalPDF: S.String.pipe(addAnnotations({ identifier: 'Original-PDF' }), S.optional()),
    sheet: S.Number.pipe(
      S.int(),
      addAnnotations({ identifier: 'sheet_num' }),
      S.optional()
    ),
  })))

export interface DocumentKindEncoded extends S.Schema.Encoded<typeof _$DocumentKind> { }
export interface DocumentKind extends S.Schema.Type<typeof _$DocumentKind> { }
export interface $DocumentKind extends S.Annotable<$DocumentKind, DocumentKind, DocumentKindEncoded> { }
export const $DocumentKind: $DocumentKind = _$DocumentKind
