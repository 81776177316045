import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom"


import { RootLayout } from './Layouts/Root'
import { Home } from './Layouts/Home'
import { MainHelp } from './components/Help'
import { NotFound } from './components/NotFound'

//About Pages
import { AboutFS } from './components/About/FredSammons'
import { AboutR2D2 } from './components/About/R2D2'
import { AboutFSA } from './components/About/FredSammonsArchives'

//Accessibility Pages
import { AccessStatement } from './components/A11y/A11yStatement'
import { WebsiteEqTDs } from './components/A11y/EqTDs'

//Misc Pages
import { PrivacyPolicy } from './components/Legal/PrivacyPolicy'
import { Disclaimer } from './components/Legal/Disclaimer'

import { SearchResults, ResultsLoader } from './components/Search'
import { Sidebar } from './Layouts/Sidebar'
import { ArtifactLoader, ArtifactRouter } from './components/Artifact'

export const RootRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="/*" element={<Sidebar />}>

        <Route path="search/results/" element={<SearchResults />} loader={ResultsLoader} />

        <Route path="artifact/:id" loader={ArtifactLoader} element={<ArtifactRouter />} />
        <Route path="artifacts/:id" loader={ArtifactLoader} element={<ArtifactRouter />} />

        <Route path="about">
          <Route index element={<AboutFSA />} />
          <Route path="about-r2d2" element={<AboutR2D2 />} />
          <Route path="about-fs" element={<AboutFS />} />
        </Route>

        <Route path="accessibility">
          <Route index element={<AccessStatement />} />
          <Route path="website-eqtds" element={<WebsiteEqTDs />} />
        </Route>

        <Route path="help" element={<MainHelp />} />

        <Route path='disclaimer' element={<Disclaimer />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Route>
  )
)
