import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $CategoryName, $Format, $JPG, $PDF } from "../types"
import { addAnnotations } from "../addAnnotations"
import { OptionalArray } from "../types/utils"

export const PictureKindModel = {
  code: 'P',
  name: 'Picture',
  extensions: [$PDF],
  description: 'Picture',
//description: "Icon representing a picture",

  icon:  'icon_images',
  alt: "An icon that represents images in the Fred Sammons Archive database.",
  plural: 'Images',
  searchFields: ["Titles & Keywords", "Descriptions", "EqTDs"],
} as const satisfies BaseKindModel 

const _$PictureKind = createArtifactKind(PictureKindModel) // P
  .pipe(S.extend(S.Struct({
    format: OptionalArray($Format, 'Formats'),
    categories: OptionalArray($CategoryName, 'Categories'),

    //originalPDF: S.String.pipe(addAnnotations({ identifier: 'Original-PDF'}), S.optional()),
    sheet: S.Number.pipe(
      S.int(),
      addAnnotations({ identifier: 'sheet_num' }),
      S.optional()
    ),
  })))

export interface PictureKindEncoded extends S.Schema.Encoded<typeof _$PictureKind> { }
export interface PictureKind extends S.Schema.Type<typeof _$PictureKind> { }
export interface $PictureKind extends S.Annotable<$PictureKind, PictureKind, PictureKindEncoded> { }
export const $PictureKind: $PictureKind = _$PictureKind
