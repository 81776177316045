import React from 'react'
import { VideoArtifact, YoutubeIdFromVideoURL } from "@repo/schema"
import { EntryArtifactFC, EntryFC } from "../types"
import { YoutubeEmbed } from "../../Youtube"
import { Mp3 } from '../../Mp3'
import { EntryProperty } from '../utils'
import { Topics } from './common'

// const mediaLink = artifact.kind.mediaLink + "?rel=0&cc_load_policy=1"
// 
export const Video: EntryArtifactFC<VideoArtifact> = ({ entry, artifact }) => <>
  <LongDescription entry={entry} />
  <Mp3 id="specificAudioDescription" title="Audio Description for Video" entry={entry} />
  <YoutubeEmbed id={YoutubeIdFromVideoURL(entry.kind.mediaLink ?? '') ?? ''} />
  <dl>
    <Topics topics={entry.kind.topics ?? []} />
    <EntryProperty label="Video Length">{entry.kind.duration}</EntryProperty>
    <EntryProperty label="Clip #">{entry.kind.clip}</EntryProperty>
  </dl>
</>

export const LongDescription: EntryFC<VideoArtifact> = ({ entry: { kind: { longDescription } } }) =>
  longDescription
    ? <EntryProperty label="Overview">{longDescription}</EntryProperty>
    : null
