import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () =>
  <div className="footer" role="content information">
    <div className="footerText">
      <p>The Fred Sammons Archives were donated to the Rehabilitation, Research, Disability and Design Center at the
        University of Wisconsin-Milwaukee in 2014, where it was archived, organized, and published.  Connect with us
        to learn more about this project and our accessibility and rehabilitation engineering projects.
      </p>
      <p>
        <Link to="/disclaimer">disclaimer</Link><Link to="/privacy-policy">privacy policy</Link>
      </p>
    </div>
    <div className="footerImage">
      <a href="http://www.r2d2.uwm.edu"><img src="/img/logos/r2d2_logo.png" alt="Rehabilitation Research Design and Disability Center Logo" /></a>
    </div>
    <div className="footerImage">
      <a href="https://uwm.edu"><img src="/img/uwm_white.png" alt="University of Wisconsin-Milwaukee Logo" /></a>
    </div>
  </div>
