import React, { useCallback } from 'react'
import { Outlet } from 'react-router'
import { useSearchState } from '../hooks/useSearch'
import { Form } from 'react-router-dom'
import { SearchText } from '../components/Search/SearchText'
import { useQuerySubmitter } from '../hooks/useQueryState'
import { TogglerEras, TogglerTypePicker } from '../components/Pickers'

export const Sidebar = () =>
  <div className="Route">
    <SidebarSearch />
    <div className="content"><Outlet /></div>
  </div>


export const SidebarSearch = () => {
  const { queryState } = useSearchState()
  const handleSubmit = useQuerySubmitter(() => queryState)
  const { phrase, types, eras } = queryState

  // const searchFields = queryState
  //   .searchFields
  //   .map(name => <div className='field' key={name}><p>{name}</p></div>)

  return <Form className="Sidebar search" role='search' onSubmit={handleSubmit}>
    <h1>Search the Archives</h1>

    <SearchText value={phrase.value} onChange={phrase.setValue} />
    <button type="submit" className="SearchButton">Search</button>

    <div className="fieldPicker">
      <h2>Refine your search:</h2>
      <TogglerTypePicker picker={types} label="Types" />
      <hr />

      <TogglerEras picker={eras} label="Eras" />
    </div>
  </Form>
}
//      <h2>Searching These Fields:</h2>



// const errorMessage = "A search term is required"

// /**
//  * Combines specific types and maps them to their corresponding objects.
//  *
//  * The function combines 'U' and 'F' types into 'UF' if both are present.
//  * It then maps the types to their corresponding objects from a predefined list.
//  *
//  * @param {Object} query - The query object containing the types string.
//       * @returns {Array} - Array of type objects mapped from the input string.
//       */
// const parseTypes = (query) => {
//   const typesAvailable = (query.types ?? '').split('')
//   if (typesAvailable.length === 0) return []

//   const combinedTypes = typesAvailable.includes('U') && typesAvailable.includes('F')
//     ? typesAvailable
//       .filter(type => type !== 'F')
//       .map(type => type === 'U' ? 'UF' : type)
//     : typesAvailable

//   return ArtifactTypes.filter(typeObj => combinedTypes.includes(typeObj.key))
// }
