import React from 'react'
export type YoutubeEmbedProps = {
  height?: number
  width?: number
  frameBorder?: string
} & ({ id: number | string } | { src: string })

export const YoutubeEmbed: React.FC<YoutubeEmbedProps> = (props) => {
  const { height = 450, width = 800, frameBorder = "0" } = props
  const src = 'id' in props
    ? `https://www.youtube.com/embed/${props.id}?rel=0&ampcc_load_policy=1` // ?rel=0&cc_load_policy=1
    : props.src
  return <div className="videoEmbed">
    <iframe
      src={src}
      width={width}
      height={height}
      frameBorder={frameBorder}
      allowFullScreen
    >
    </iframe>
  </div>
}
