import * as S from "@effect/schema/Schema"
import { extensions, $MediaLink, SearchField, } from "../types"
import { addAnnotations } from "../addAnnotations"

export interface BaseKindModel<
  Code extends string = any,
  Name extends string = any,
  Extensions extends ReadonlyArray<typeof extensions[number]> = any,
  Fields extends ReadonlyArray<SearchField> = any,
> {
  code: Code
  name: Name
  extensions: Extensions
  searchFields: Fields
  description: string
  plural: string

  icon: string,
  alt: string
}

export const createArtifactKind = <Code extends string,
  Name extends string,
  Extensions extends ReadonlyArray<typeof extensions[number]>,
  Fields extends ReadonlyArray<SearchField>,
>({
  code, name, extensions, searchFields, description, plural, icon, alt,
}: BaseKindModel<Code,Name,Extensions,Fields>) =>
  S.Struct({
    name: S.Literal(name).pipe(addAnnotations({ identifier: 'Kind-name' })),
    extension: S.Union(...extensions).pipe(addAnnotations({ identifier: 'Media-extension' })),

    // TODO: Optional because missing in 3 EqTDs (2016,2024,2027) and 1 FredNotes (77)
    mediaLink: $MediaLink.pipe(addAnnotations({ identifier: 'MediaLink' }), S.optional()),
    // filename: FileName,
  }).pipe(
    S.attachPropertySignature('code', code),
    // S.attachPropertySignature('plural', plural),
    // S.attachPropertySignature('description', description),
    // S.attachPropertySignature('alt', alt),
    // S.attachPropertySignature('icon', icon),
    // S.attachPropertySignature('fields', searchFields),
    addAnnotations({
      identifier: name,
      pretty: () => (v) => `Kind: ${name}`,
    })
  )

//class ArtifactKind extends S.Class<ArtifactKind>('ArtifactKind')({}) {}
