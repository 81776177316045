import * as S from "@effect/schema/Schema"
//import * as AST from "@effect/schema/AST"
//import * as ParseResult from "@effect/schema/ParseResult"
//import { Record as ReadonlyRecord } from "effect"

// export * from './numbers'
// export * from './tuples'

export const addAnnotations =
  <T extends S.Annotable.All>(annotations: S.Annotations.Schema<S.Schema.Type<T>>): (self: T) => S.Annotable.Self<T> => {
    if (!annotations.title) {
      if (annotations.identifier) return S.annotations({ ...annotations, title: annotations.identifier })
    }
    return S.annotations(annotations)
  }
