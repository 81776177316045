import { pipe } from 'effect'
import { Schema as S } from "@effect/schema"
import { Api } from "effect-http"
import * as FSA from '@repo/schema'

export const $AllArtifactsResponse = S.Array(FSA.$Artifact)
  // S.array(S.struct({ id: FSA.$Artifact }))

export const AllArtifactsAPI = pipe(
  Api.get("AllArtifacts", "/artifacts/"),
  Api.setResponseBody(S.Array(FSA.$Artifact)),
)
