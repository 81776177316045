import React, { CSSProperties } from 'react'
import { Artifact, Artifacts, KindByName } from '@repo/schema'
import { ArtifactIdTag, EntryInfo, TagLabel } from '../Search/EntryInfo'
import { createMediaLink, EntryLink, Keywords, KindFigure, ReactFCC } from '../utils'
import { Era, ShortDescription } from './sections'
import { EntryArtifactFC, EntryArtifactProp, EntryFC, EntryFCC } from './types'
import { config } from "../../config"
import { Mp3 } from '../Mp3'
import { EntryProperty } from './utils'
import { MatchArtifactKind } from '.'

export interface BaseArtifact extends EntryArtifactProp<Artifact> { downloadLink?: string }
const ArtifactHeader: EntryArtifactFC = ({ entry, artifact }) =>
  <header>
    <div className='IconKindWrapper'><KindFigure entry={entry} /></div>
    <h1 className='ArtifactHeading'>
      {entry.title ?? artifact.primary.title} <ArtifactIdTag entry={entry} style={{ fontSize: 'large' }} />
      <EntryInfo entry={entry} artifact={artifact} style={{ fontSize: 'large' }} />
    </h1>
  </header>

export const BaseArtifact: ReactFCC<BaseArtifact> = ({ entry, artifact, children }) =>
  <div className="ArtifactMedia">
    <NavHeader entry={entry} />
    <ArtifactHeader entry={entry} artifact={artifact} />

    <PrimaryEntryLink entry={entry} />
    <ShortDescription entry={entry} />

    <div className='children'>{children}</div>
    <BaseArtifactMeta entry={entry} />
    <SecondaryEntryEmbed entry={entry} artifact={artifact} />
    <RelatedArtifacts entry={entry} />
    {entry.kind.name === 'Video' && <section>
      <Mp3 id="generalAudioDescription" title="General Audio Description for All Archive Videos" src="1024" />
    </section>}

    <SecondaryEntryLinks entry={entry} artifact={artifact} />
  </div>

export const BaseArtifactMeta: EntryFCC = ({ entry }) =>
  <section className='ArtifactMeta'>
    <dl>
      <Era entry={entry} />
      {entry.years && <dl><EntryProperty label='Year'>{entry.years}</EntryProperty></dl>}
    </dl>
    <Keywords keywords={entry.keywords} />
  </section>

export const PrimaryEntryLink: EntryFC = ({ entry }) =>
  entry.isPrimary
    ? <></>
    : <div>This is a sub-entry of Artifact #{entry.primaryId}. <EntryLink id={entry.primaryId}>Go here</EntryLink> to view the primary entry.</div>

const SecondaryLinkStyle = {
  style: {
    border: '1px',
    padding: '2px'
  }
} as const
export const SecondaryEntryLinks: EntryArtifactFC = ({ artifact, entry }) => {
  if (!entry.isPrimary || artifact.secondary.length === 0) return <></>
  const links = artifact.secondary
    .map(secondary =>
      <li key={secondary.id}>
        <EntryLink id={secondary.id} props={SecondaryLinkStyle}>
          {secondary.count}: {KindByName[secondary.kind.name].description}
        </EntryLink>
      </li>
    )
  return <section className='SecondaryArtifactLinks'>
    <span>View Secondary Entries for this artifact individually:</span>
    <ul className="indentation">{links}</ul>
  </section>
}

export const SecondaryEntryEmbed: EntryArtifactFC = ({ entry, artifact }) => {
  if (!entry.isPrimary || artifact.secondary.length === 0) return <></>
  const secondaryEmbeds = artifact.secondary
    // Don't show AudioDescription as a secondary embed because it
    // is already shown before the document
    .filter(secondary => secondary.kind.name !== 'AudioDescription')
    .map((secondary, idx) => {
      const name = secondary.kind.name === 'Object'
        ? 'Supplementary Object Image'
        : KindByName[secondary.kind.name].description
      return <section key={secondary.id}>
        {idx > 0 && <hr />}
        <h3>{name}</h3>
        {MatchArtifactKind(secondary, artifact)}
      </section>
    })
  return <div className='SecondaryArtifactEmbeds'>{secondaryEmbeds}</div>
}

const RelatedArtifacts: EntryFC = ({ entry: { relatedArtifacts } }) => {
  if (!relatedArtifacts || relatedArtifacts.length === 0) return <></>
  const artifacts = relatedArtifacts
    .map(id => <li key={id}><EntryLink id={id}>Artifact #{id}</EntryLink></li>)

  return <section>
    <strong>Related Artifacts:</strong>
    <ul className="indentation">{artifacts}</ul>
  </section>
}

const NavHeader: EntryFC = ({ entry }) => <></>
  // <div className='ArtifactNavHeader' style={{ display: 'flex', justifyContent: 'space-between' }}>
  //   <EntryLink id={entry.id - 1}>← Previous Entry (#{entry.id - 1})</EntryLink>
  //   <span>Currently viewing entry #{entry.id}</span>
  //   <EntryLink id={entry.id + 1}>Next Entry (#{entry.id + 1}) →</EntryLink>
  // </div>
