import React from 'react'
import { Link, NavLink } from 'react-router-dom'

export const Header = () =>
  <div className="header">
    <a href="/"><img src="/img/logos/FSA_logo_white.png" alt="The Fred Sammons Archives Logo." /></a>
    <NavBar />
  </div>

export const NavBar = () =>
  <div id='NavBar'>
    <ul>
      <li className='option'><NavLink to="/">Home</NavLink></li>
      <li className='option'><NavLink to="/about">About</NavLink></li>
      <li className='option'><NavLink to="/accessibility">Accessibility</NavLink></li>
      <li className='option'><NavLink to="/help">Help</NavLink></li>
    </ul>
  </div>
