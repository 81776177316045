import React from 'react'

interface SearchTextProps {
  value: string
  onChange(value: string): void
}

export const SearchText: React.FC<SearchTextProps> = ({ value, onChange }) =>
  <input type="text"
    id="search"
    name="q"
    value={value}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
    className="text-search-input"
    aria-label="Search Form"
    placeholder="Search the archives..."
  />
