import React, { useState, useCallback } from 'react'
import { Effect, pipe, Console } from 'effect'
import { Client, ClientError } from "effect-http"
import { SearchResult, api } from '@fredsammons/api'

import { useSearchResults } from './useSearchResults'
import { SearchQueryState, updateSearchQuery, useQueryState } from './useQueryState'
import { config } from '../config'
// import { useLocation } from 'react-router'

const client = Client.make(api, { baseUrl: config.base_url() })

// const queryString = new URLSearchParams(globalStorage.state.query)
export const useSearchState = () => {
  const [error, setError] = useState<ClientError.ClientError | undefined>(undefined)
  const [status, setStatus] = useState<SearchStatus>('UnStarted')
  const resultState = useSearchResults()
  const queryState = useQueryState()

  const doSearch = useCallback((query: SearchQueryState['query']) =>
    pipe(
      Effect.sync(() => setStatus('Loading')),
      Effect.andThen(() => client.SearchArtifacts({ query })),
      Effect.tapBoth({
        onSuccess(response) {
          setError(undefined)
          return Console.log('Query Succeeded:', query, response)
        },
        onFailure(response) {
          console.log('Error:', response)
          resultState.clear()
          setError(response)
          return Console.log('Query Failed', query, response)
        },
      }),
      Effect.ensuring(Effect.sync(() => setStatus('Done'))),
    ), [])

  const search = useCallback(() => {
    queryState.setPage(0)
    return pipe(
      doSearch({ ...queryState.query, page: 0 }),
      Effect.andThen(resultState.setResponse),
      Effect.map(() => updateSearchQuery(queryState, { page: 0 })),
      Effect.runPromise,
    )
  }, [queryState, queryState.query])

  const getNextPage = useCallback(() => {
    const page = queryState.page + 1
    queryState.setPage(page)
    return pipe(
      doSearch({ ...queryState.query, page }),
      Effect.andThen(resultState.append),
      Effect.map(() => updateSearchQuery(queryState, { page })),
      Effect.runPromise,
    )
  }, [queryState, queryState.query])

  return {
    search, queryState, resultState, status, error,
    getNextPage, page: queryState.page,
  } as const
}

export interface SearchState {
  search(query: SearchQueryState): void
  params: any
  queryState: SearchQueryState
  page: number
  getNextPage(): void
  resultState: SearchResult[]
  status: SearchStatus
  error: ClientError.ClientError | undefined
}

export type SearchStatus = 'UnStarted' | 'Loading' | 'Done'

export interface SearchState {
  query: SearchQueryState
  resultState: SearchResult[]
  search(query: SearchQueryState): void
}
