import * as S from "@effect/schema/Schema"
import { BaseKindModel, createArtifactKind } from "./BaseKind"
import { $TXT } from "../types"

export const FredsNotesKindModel = {
  code: 'R',
  name: 'FredsNotes',
  extensions: [$TXT],
  plural: 'FredsNotes',

  icon: '',
  alt: '',
  description: '',
  searchFields: [],
} as const satisfies BaseKindModel 

const _$FredsNotesKind = createArtifactKind(FredsNotesKindModel)

export interface FredsNotesKindEncoded extends S.Schema.Encoded<typeof _$FredsNotesKind> {}
export interface FredsNotesKind extends S.Schema.Type<typeof _$FredsNotesKind> {}
export interface $FredsNotesKind extends S.Annotable<$FredsNotesKind, FredsNotesKind, FredsNotesKindEncoded> { }
export const $FredsNotesKind: $FredsNotesKind = _$FredsNotesKind
