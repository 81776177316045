import React from 'react'
import { Link } from 'react-router-dom'
import { R2D2 } from '../utils'
import { YoutubeEmbed } from '../Youtube'
import { Mp3 } from '../Mp3'

export const AboutFSA: React.FC = () =>
  <div>
    <div className="breadcrumb menu">
      <ul>
        <li><Link to="/about" className='item active'>About the Archives</Link></li>
        <li><Link to="/about/about-fs" className='item'>About Fred Sammons</Link></li>
        <li><Link to="/about/about-r2d2" className='item'>About the <R2D2 /> Center</Link></li>
      </ul>
    </div>
    <div>
      <h1>About the Fred Sammons Archives</h1>
      <div className="toc">
        <h2>Table of Contents</h2>
        <ol>
          <li><a href="about#overview">Overview of the Fred Sammons Archives</a></li>
          <li><a href="about#introvideos">Introductory Videos for the Archives</a></li>
          <li><a href="about#db">Master Archive Resources</a></li>
          <li><a href="about#team">The Fred Sammons Archive Development Team</a></li>
        </ol>
      </div>
      <p><a name="overview" id="overview">The Fred Sammons Archive Project,</a> at the University of Wisconsin-Milwaukee <R2D2 /> Center, organizes the entirety of Fred Sammons’ archives
        personal, business, and career-related into a digitized and searchable online database for public use. It provides accessible references
        for students, educators, researchers, and practitioners. The archival material contains 4,800 pages of documents, 30+ hours of video, and
        70 physical artifacts, including product prototypes.</p>
      <p>As a part of developing this archive, Dr. Roger O. Smith conducted 10 studio interviews with Fred about his upbringing, early career,
        product creative process, and the philosophies underlying this revolutionary inventor and educator.</p>
      <div className="aboutImg"><img src="/img/currentsammonssmith-thumb.png" alt="A photograph of Roger Smith and Fred Sammons at the American Occupational Therapy Association Conference." /></div>
      <p>The website (www.fredsammons.org) hosts the archival materials for worldwide access. It’s design intentionally contains accessibility
        components futuristic for such archives. These consist of audio descriptions, closed captions, and transcriptions. These also include brief
        and essential text descriptions of illustrations or illegible documents, artifacts, and photographs. The site also presents “Fred’s notes,”
        where Fred, himself, has recorded audio descriptions of many of his handwritten memos, doodles, margin notations, and drawings. </p>
      <p><a href="http://uwm.edu/healthsciences/news/renowned-ot-pioneer-fred-sammons-donates-collection-to-uw-milwaukee-archives/">For another brief overview, see this article.</a></p>
      <hr />
      <div className="aboutVideo">
        <a name="introvideos" id="introvideos"><h1>Introductory Videos for the Fred Sammons Archives</h1></a>
        <h2>Welcome to the Fred Sammons Archive</h2>
        <h2><small>Roger welcomes Fred and describes the interview process.</small></h2>
        <Mp3 title="Audio Introduction" id="WelcomeAudioDescr" src="476" />
        <YoutubeEmbed id="XwDbjSz5DoM" />

        <br />
        <h2>Introduction to the Fred Sammons Archive</h2>
        <h2><small>Roger introduces the overall content and organization of the Fred Sammons Archives.</small></h2>
        <Mp3 title="Audio Introduction" id="IntroAudioDescr" src="817" />
        <YoutubeEmbed id="sIF3mevpQr0" />

      </div>
      <hr />
      <a name="db" id="db"><h1>Master Archive Resources</h1></a>
      <h2>Physical Artifacts</h2>
      <p>The archive physical artifacts, including documents, photographs, and objects are stored at the Wilma L West Library at the American Occupational Therapy Foundation (AOTF) headquarters in Bethesda, Maryland. The address of the AOTF headquarters is:
      </p>
      <p>
        4720 Montgomery Lane<br />
        Suite 202<br />
        Bethesda, MD 20814<br />
      </p>
      <p>
        If a researcher would like to see the artifacts in person, contact Mindy A. Hecker at mhecker@aotf.org or call at 240-292-1045.
      </p>
      <p>
        The physical artifacts are archived in 9 separate boxes.  The master database spreadsheet identifies the box location of each artifact. To access the correct box the researcher will need the artifact ID number of the artifact. The artifact ID number in column B (artifact_ID) and corresponds with column F (aotf_box)  that identifies the box where the artifact resides.
      </p>
      <h2>Database Codes and Download</h2>
      <p>Download of the full metadata for the electronic files available on the Fred Sammons Archives here. The physical storage box numbers are listed in this spreadsheet, so in the event that the physical artifact is needed, the item can be found in the specified storage box.</p>
      <div className="downloadButtonCenter"><p><a href="/data/MasterSpreadsheet.xlsx">Master Archive Database Record</a></p></div>
      <h2>Full Videos of Interviews</h2>
      <p>The following videos showcase full days of interviews, and may be of interest to researchers.</p>
      <div className="aboutVideo">
        <Interview id="512" youtube="1UlCeg9ptNM" name="fullday1" label="Day One Interviews" /><br />
        <Interview id="550" youtube="MxbFYmP9AAo" name="fullday2" label="Day Two Interviews" /><br />
        <Interview id="588" youtube="5knezB4fAR8" name="fullday3" label="Day Three Interviews" /><br />
        <Interview id="615" youtube="LqNk84fjRSw" name="fullday4" label="Day Four Interviews" /><br />
        <Interview id="658" youtube="90suS51gk4w" name="fullday5" label="Day Five Interviews" /><br />
        <Interview id="698" youtube="o6spMuctl6s" name="fullday6" label="Day Six Interviews" /><br />
        <Interview id="733" youtube="dKG45pxbkxc" name="fullday7" label="Day Seven Interviews" /><br />
        <Interview id="774" youtube="XWpT1yb2OMA" name="fullday8" label="Day Eight Interviews" /><br />
        <Interview id="816" youtube="AuAd0sHFV5A" name="fullday9" label="Day Nine Interviews" /><br />
        <Interview id="847" youtube="fDZS0cSXYco" name="fullday10" label="Day Ten Interviews" />
      </div>
      <hr />
      <a name="team" id="team"><h1>The Fred Sammons Archive Development Team</h1></a>
      <p>We thank the following team members for their contribution to creating the Fred Sammons Archives.</p>
      <h2><R2D2 /> Center</h2>
      <ul>
        <li>Roger O. Smith</li>
        <li>Nathan Spaeth</li>
        <li>Drew Williams</li>
        <li>Colleen Regan </li>
        <li>Jack Skelton- Miller</li>
        <li>Meg Zimont</li>
        <li>Ariana Pelkey</li>
        <li>Nicole Latzig</li>
        <li>Bavana Gaddam</li>
        <li>Caitlin Dobson</li>
        <li>Kathryn Kober</li>
      </ul>
      <h2>University of Wisconsin- Milwaukee</h2>
      <ul>
        <li>Frank Campenni</li>
        <li>Jessica McCall</li>
        <li>Lionel Rocheleau</li>
      </ul>
      <h2>Other Parties</h2>
      <ul>
        <li>Creekside Digital</li>
        <li>Fred Sammons</li>
        <li>Fred Brack</li>
        <li>Terry VanderMeer</li>
        <li>Rev.Com</li>
        <li>American Occupational Therapy Foundation (AOTF)</li>
      </ul>
    </div>
  </div>


export interface InterviewProps {
  name: string
  label: string
  id: string
  youtube: string
}
const Interview: React.FC<InterviewProps> = ({ name, label, id, youtube }) =>
  <>
    <a name={name}><h1>{label}</h1></a>
    <Mp3 title="Audio Introduction" id={name + 'Audio'} src={id} />
    <YoutubeEmbed id={youtube} />
  </>
