import React from 'react'
import { R2D2 } from './utils'
export const MainHelp = () => {
  return (
    <div>
      <div>
        <h1>Help for the Fred Sammons Archives</h1>
        <div className="toc">
          <h2>Table of Contents</h2>
          <ol>
            <li><a href="#generalhelp">Overall Site Layout</a></li>
            <li><a href="#archivesearch">Searching the Archive</a></li>
            <li><a href="#home">Home Page</a></li>
            <li><a href="#searchres">Search Results</a></li>
            <li><a href="#sidebar">Left Margin Search Bar</a></li>
            <li><a href="#artifact">Artifact Page</a></li>
            <li><a href="#case">Case Studies</a></li>
          </ol>
        </div>
        <section>
          <h3><a name="generalhelp" id="generalhelp">1. Layout of the Website</a></h3>
          <p>Every page of the website has a header, main body, and footer. The headers and footers always have the same format while the main body will change depending on what page of the website the user is utilizing. Both the header and footer have dark grey backgrounds with white lettering. The header of the page contains the Fred Sammons Archive logo in the left hand corner and then tabs to different pages are spread across to the right. The different tabs included home, about, accessibility, and contact. A teal rectangle will highlight which page the user is on and the letter will also change from white to yellow. The Fred Sammons Archive logo can also be clicked on to return to the home page. The footer of the home page contains information on how the Fred Sammons Archive website was created, the University of Wisconsin-Milwaukee logo, and the <R2D2 /> Center logo. When the user clicks on the logos, it will take them to the University of Wisconsin-Milwaukee website and the <R2D2 /> website respectively. There are also links to the disclaimer page and the private policy page at the bottom left of the footer.</p>
        </section>

        <h3><a name="archivesearch" id="archivesearch">2. Searching the Archive</a></h3>
        <p>The Fred Sammons Archive Website uses Boolean logic when searching for artifacts within the website. Boolean logic is a simple way of refining search parameters so the researcher can get the best results possible. The Fred Sammons Archive Website has over 1,000 artifacts and the developers wanted to ensure that the searching features of the website produced the most relevant information. How Boolean logic operates is by using special search operators within the search. These search operators are AND, OR, NOT, asterisks (*), parentheses ( ), and quotation marks (“ “). Boolean logic will search within the titles, descriptions, key words, text files, and transcripts of all the artifacts for the specific search words that the researcher is looking for.</p>
        <div className="indentation">
          <h4><a name="so-and" id="so-and">2a. Search Operator: AND</a></h4>
          <p>The word AND is used to include multiple words within your search and thus will limit your search. For example. If the researcher typed into the search box button hook AND catalogs boolean logic will search the archives for both of those words within the archive and only show artifacts that contains both of those words within the artifact.</p>

          <h4><a name="so-or" id="so-or">2b. Search Operator: OR</a></h4>
          <p>The word OR is used to broaden the search parameters and offers a more flexible inclusion of archive results. The OR operator is interpreted as at least one search word is required. However if more than one of the search words are found in the archive, all of those results will be populated on the search results page. Using the example of button hook OR catalogs, the archive will search for all artifacts that contain the word button hook, or catalogs, or artifacts that contains both of these words. This will result in a larger search results due to the archive looking for 3 different scenarios. </p>

          <h4><a name="so-not" id="so-not">2c. Search Operator: NOT</a></h4>
          <p>The word NOT is used to exclude specific terms and words from the archive search so the search results page will not include any archive items that have those words. For example, if the researcher was searching catalog NOT Sammons Preston, the query will populate any archive item that has the word catalog but does not included Sammons Preston.  Another uncommon usage for the search operator NOT is if the researcher started with a very restrictive search with a lot of different search words. The researcher can use NOT to loosen the search parameter into exclusive result sets. </p>
          <h4><a name="so-ast" id="so-ast">2d. Search Operator: Asterisk (*)</a></h4>
          <p>The asterisks symbol is used for searching for any prefix or suffix of a word. An example would be if a researcher searches for button* the archive will search for any suffix form of button. Some examples of this would be button, buttons, buttoning, button hook, button-aid, Button-King, etc. Another scenario would be if the research searched *aid, the search results would show aid, button-aid, Self Help Aid, sock aid, etc.</p>

          <h4><a name="so-quote" id="so-quote">2e. Search Operator: Quotation Marks (" ")</a></h4>
          <p>Quotation Marks are used when searching for an exact phrase of more than one word. If the quotations are not used, Boolean logic will split the phrase into single word components. An example of this is if a researcher only wanted artifacts that contained the phrase “BK Enterprises”. The search query would only display artifacts that contained that phrase within the title, keywords, descriptions, or transcriptions. </p>
        </div>

        <h3><a name="home" id="home">3. Home Page</a></h3>
        <p>This webpage is the first page that the user will see on the Fred Sammons Archive website. This is where the user will start their preliminary search within the archive. The user can type in a keyword or phrase in the ‘search the archive’ bar. This bar is colored gray. Below the search bar, the user can refine their search by selecting specific parameters. The two parameters are by artifact type and Fred’s Eras. Both of the parameters are automatically set on all artifact types and all eras when beginning a search. The different options for artifact type are Images, Documents, Audio, Video, and Objects. The user can select which artifact types they want to search within by selecting on the corresponding icons.  The different options for the Fred’s Eras are Early Years, College Years, Early Career, Early Company, Maturing Company, Pre-Bissel, and Post-Bissel. The user has to use the same method as the artifact types to select the intended era. When the user is satisfied with their keywords and search parameters, they can click on the teal search button.</p>

        <h3><a name="searchres" id="searchres">4. Search Results</a></h3>
        <p>After selecting the search button on the home page, the user will be directed to the search results page. In the top left, the webpage will show how many artifacts are listed on the page out of how many total artifacts there are from the search. Below the search result numbers are the individual artifacts. Each artifact result has the artifact number and title, the year the artifact is from, a short description of the artifact, and keywords.  To the left of the description is the artifact type with its corresponding icon. To select an artifact, the user can click on either the artifact icon or the artifact number and title. If the user wants to load more of their search results, they will need to scroll to the bottom of the page and click on the yellow rectangular ‘load more results’ bar.</p>

        <h3><a name="sidebar" id="sidebar">5. Left Margin Search bar</a></h3>
        <p>The left-sided search navigation column has a yellow background with separate toggles for search parameters (artifact type and Fred’s eras). The search bar automatically retains previous keywords and search parameters from the last search. Any search can be modified by changing the keywords or search parameters by clicking on the corresponding toggle to either select or deselect the artifact type or eras. When the toggles are selected, a green check mark will appear next to the search parameter. When the toggle is not selected, a grey X will appear.</p>

        <h3><a name="artifact" id="artifact">6. Artifact Pages</a></h3>
        <p>Once the user selects an artifact, they will be directed to the artifact page. The artifact number, title, and artifact type are listed at the top of the page. In videos, the Audio Introduction to the video is present below the artifact number, title, and type. The audio introduction explains the video interview and what is being discussed between Fred Sammons and Roger Smith.  Below these items are the artifacts.</p>
        <p>If the artifact is a document, the artifact will preview the first page of the document. If the user wants to view the entire document, they need to click on the teal button below the first page preview. To view a video interview, the user needs to click on the play button that is in the middle of the video. If the artifact is an image, the photograph will be displayed on the webpage. If the user wants to take a closer look at the photograph, they need to click on the teal download media button.  For objects, the artifact page may display multiple views of the object. If the user wants larger views of the object, they need to click on the teal download media button beneath the first image of the object. For audio, the artifact page will show the document that the audio description is describing. The audio description is titled as “Fred’s Notes” and is at the bottom of the page.</p>
        <p>Below the artifacts are the document properties, the era the artifact was created in, the category of artifact (planning, organization, marketing, legal, public relations, donations, creations, production, sales/catalogs, clinical practice, or occupational therapy (OT) education), and keywords. For images and objects, there are equivalent text descriptions (EqTDs) for the photographs. The EqTDs include brief and essential descriptions. For audio artifacts, Fred’s Notes are at the bottom of the artifact page.</p>

        <h3><a name="case" id="case">7. Case Studies</a></h3>
        <div className="indentation">
          <h4><a name="case1" id="case1">7a. First Case</a></h4>
          <p>An occupational therapy student at the University of Wisconsin-Milwaukee is writing a research paper on the history of devices used to don and doff clothing, with a specific interest in buttoning hooks. The student decides to use the Fred Sammons Archive Website to look up resources. When the student arrives at the home page of www.fredsammons.org, she types in button AND hook into the search bar and then selects the video and objects icons in the artifact types. In the Fred’s Eras section, the student selects ‘All Eras’ because she wants to find all the information there is about button hooks throughout Fred Sammons’s life. The search results page shows 25 of the several dozens of items in the results. The student reads through the title, the description, and keywords of the artifacts and decides to view Artifact #659: Initial Button Hook. When the student clicks on the artifact title, it takes her to a video interview. She watches the video and decides that she wants to use this interview in her paper. However, she wants to use a direct quote from the video, but doesn’t want to re-watch the entire interview. She scrolls down the page and clicks on the ‘Download Transcripts of Video’ button. She scans through the written dialog of the video interview finding her quote quickly without needing to watch it again.</p>

          <h4><a name="case2" id="case2">7b. Second Case</a></h4>
          <p>A man with severe low vision due to glaucoma is a long time user of Sammons-Preston adaptive equipment products and decides he wants to learn more about Fred Sammons how he became the inventor and entrepreneur he is today. The man has a screen reader software program on his personal computer so he can easily read the typed text on the website but he wants to see what accessibility components the Fred Sammons Archive Website has if he wants to view a picture or a read a handwritten document. At the home page, the man types ‘Fred’ into the search bar and selects the images icon in the Artifact Types search parameters. The first result on the results page was ‘Artifact #4: News Release Photo’. The artifact page contains a picture of Fred and as the man scrolls down the page, he discovers a section called ‘Equivalent Text Descriptions’. These descriptions told the man the extra details and context of the photo that he could not visually see due to his vision impairment. Next, the man wanted to find a handwritten letter from Fred. From the side bar on the artifact page, the man changes the search keyword to ‘handwritten’ and refined the search parameters from images to documents. The man selects Artifact #80- Exact Sequence of Hospital Sales. Since his screen reader could not decipher Fred Sammons’s cursive handwriting, his computer could not read the letter out loud. Below the document the man notices a section called ‘Fred’s Notes’ that contains an mp3 file. The man plays the file and realizes it is Fred Sammons reading the document above and adding his own personal thoughts about the document. </p>

          <h4><a name="case3" id="case3">7c. Third Case</a></h4>
          <p>A researcher is conducting research on adaptive equipment catalogs that were produced before the 1960’s. At the home page of www.fredsammons.com, the researcher types in ‘catalogs’. On the search results page, there were too many artifacts for the researcher to look through so he refined his search to ‘Catalog AND BK Sales’. The researcher also changed the Fred’s Eras parameters from All Eras to Early Company. The new search shows fewer artifacts to look through and the researcher found Artifact #232- BK Self-Help Aids Catalog which was produced in 1959. This document met all the needs that the researcher was looking for but he wanted to see it in person to see the quality of the catalog printing. In the About section of the website, the researcher found contact information for Mindy Heckler at the AOTF Library in Bethesda, MD to schedule a time where he could view the catalog in person. In preparation for his trip, the researcher downloaded the Master Archive Database Records in the about section of the website to see which box the artifact was in.  When the researcher arrived at the AOTF Library, he easily located Box 5 and was able to look at the BK Self Help Aids Catalog from 1959.</p>
        </div>
      </div>
    </div>
  )
}
