import React from 'react'
import { ReactFCC } from '../utils'

export interface EqTDProps {
  src?: string 
  url: string
  brief: string
}

export const Brief: ReactFCC = ({ children }) => <p><strong>Brief Description:</strong> {children}</p>
export const Essential: ReactFCC = ({ children }) => <p><strong>Long Description:</strong> {children}</p>
export const Detailed: ReactFCC = ({ children }) => <p><strong>Essential Description:</strong> {children}</p>

export const EqTD: ReactFCC<EqTDProps> = ({ url, brief, children }) =>
  <tr className='artifact-row'>
    <td className="artifactSearchResult">
      <figure className="EqTDImg"><a href={url}><img src={url} alt={brief} /></a></figure>
    </td>

    <td className="artifactSearchResult">
      <h3>{brief}</h3>
      <Brief>{brief}</Brief>
      {children}
    </td>
  </tr>

