import * as S from "@effect/schema/Schema"
import { pipe } from "effect"
import { addAnnotations } from "../addAnnotations"


// export const FileName = S.templateLiteral(x, $MediaExtension)
// export const FileName = S.templateLiteral(Integer, S.literal('.'), Digit, $MediaExtension)

// TODO: is ${extensions.join('|)} getting propertly parsed?
export const $FileName = pipe(
  S.String,
  S.pattern(/\d+\.\d`${extensions.join('|)}`/),
  addAnnotations({ identifier: 'Filename' }),
)
