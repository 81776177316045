import { pipe } from 'effect'
import * as S from "@effect/schema/Schema"
import { Api } from "effect-http"
import * as FSA from '@repo/schema'

export const $GetArtifactQuery = //S.Union(
  S.Struct({ id: S.NumberFromString })

export const $GetArtifactResponse = pipe(
  S.Struct({
    requested: FSA.$Artifact,
    primary: FSA.$Artifact,
    subArtifacts: FSA.$Artifacts,
  }),
  S.attachPropertySignature('_tag', 'GetArtifact'),
)

export const GetArtifactAPI = pipe(
  Api.get("getArtifact", "/artifact/:id"),
  Api.setRequestPath($GetArtifactQuery),
  Api.setResponseBody($GetArtifactResponse),
)

