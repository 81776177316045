import { clsx } from 'clsx'
import { Array, Boolean } from 'effect'
import { undefined } from 'effect/Match'
import React, { useCallback, useState } from 'react'
import Toggle from 'react-toggle'

export interface ToggleProps {
  label: string
  checked: boolean
  className?: string
  onChange: () => void
}

export const ToggleComponent: React.FC<ToggleProps> = ({ checked, onChange, label, className }) =>
  <div className={clsx('toggle-container', className)}>
    <Toggle checked={checked} onChange={onChange} aria-label={label} />
    <div className="toggle-label">{label}</div>
  </div>

export interface TogglerState<Choices extends ReadonlyArray<string>> {
  toggle(name: Choices[number]): void
  isChecked(name: string | symbol): boolean

  toggleAll(forceValue?: boolean): void

  selected: ReadonlyArray<Choices[number]>
  AllOption: AllChoice
  allSet: boolean
  choices: Choices
}
export type UseToggleFieldOptions<Choices extends ReadonlyArray<string>> =
  { initial: ReadonlyArray<Choices[number]> } |
  { initialAllSet: boolean }

const getInitialState = <Choices extends ReadonlyArray<string>>(
  choices: Choices,
  options: UseToggleFieldOptions<Choices>
): { initialAllSet: boolean, initialSelection: ReadonlyArray<Choices[number]> } => {
  if ('initial' in options) {
    if (options.initial.some(value => !choices.includes(value))) console.error('Initial values must be in choices')
    if ('allSet' in options) throw new Error('Cannot use both initial and initialAllSet')
    if (options.initial.length === 0 || Array.difference(choices, options.initial).length === 0) {
      return { initialAllSet: true, initialSelection: [] }
    }
    return { initialAllSet: false, initialSelection: options.initial }
  }
  return { initialAllSet: true, initialSelection: choices }
}


const getNewSelection = (selected: ReadonlyArray<string>, option: string) => {
  if (selected.includes(option)) {
    console.log(`Removing '${option}' from selection.`)
    return selected.filter((value: string) => option !== value)
  } else {
    console.log(`Adding '${option}' to selection.`)
    return [...selected, option]
  }
}


const computeAllValue = (forceValue?: boolean) => (value: boolean): boolean => {

}
export const AllOption = Symbol.for('AllOption')
export type AllChoice = typeof AllOption
export const useToggleField = <Choices extends ReadonlyArray<string>>(
  choices: Choices,
  options: UseToggleFieldOptions<Choices>
): TogglerState<Choices> => {
  if (choices.length === 0) throw new Error('Choices must not be empty')
  const { initialSelection: initial, initialAllSet } = getInitialState(choices, options)

  const [selected, setSelected] = useState<ReadonlyArray<Choices[number]>>(initial)

  //const setAll = useCallback((value: boolean = true) => _setAll(value), [])
  const [allSet, setAll] = useState<boolean>(initialAllSet)
  const toggleAll = useCallback((forceValue?: boolean) => {
    setAll(value => {
      if (Boolean.isBoolean(forceValue)) console.log('Forcing all to:', forceValue)
      const nextAllValue = Boolean.isBoolean(forceValue)
        ? forceValue
        : !value
      if (value == nextAllValue) console.log('No change in selection.')
      if (nextAllValue === true) console.log(`Selecting 'all'.`)
      else console.log(`Un-selecting 'all' and selecting:`, selected)
       
      return nextAllValue
    })
  }, [selected])


  const toggle = useCallback(
    (option: Choices[number] | AllChoice): void => {
      if (option === AllOption) {
        toggleAll()
        // This is just done to make sure components update
        // setSelected([..._selected])
      } else {
        if (!choices.includes(option)) console.warn(`${option} is not in:`, choices)
        if (allSet) {
          console.log(`Un-selecting 'all', selecting: '${option}'.`)
          setAll(false)
          setSelected([option])
        } else {
          const newSelected = getNewSelection(selected, option)
          setAll(newSelected.length === 0)
          setSelected(newSelected)
        }
      }
    },
    [selected, allSet]
  )
  const isChecked = useCallback(
    (option: Choices[number] | AllChoice): boolean => {
      return option === AllOption
        ? allSet
        : !allSet && selected.includes(option)
    },
    [selected, allSet]
  )

  return {
    AllOption,
    isChecked,
    allSet,
    toggle, toggleAll,
    choices,
    selected: allSet || selected.length === 0
      ? choices
      : selected,
  } as const
}
