import React, { useState, useEffect } from 'react'
import 'mediaelement/full'

interface MediaSource {
  src: string
  type: string
}
interface MediaOption {
  stretching?: 'responsive'
}

// interface Options {
//   // Specify more detailed option types as needed
//   pluginPath: string
//   success?: (media: any) => void
//   error?: (media: any) => void
// }

interface MediaElementProps {
  id: string
  sources: MediaSource[]
  options: MediaOption[]
  width?: string
  mediaType: 'audio'
}

export const MediaElement: React.FC<MediaElementProps> = ({ id, sources, options, width, mediaType }) => {
  const [player, setPlayer] = useState<any>(null)

  useEffect(() => {
    const { MediaElementPlayer } = globalThis
    if (!MediaElementPlayer) return

    const newPlayer = new MediaElementPlayer(id, Object.assign({}, options, {
      ...options,
      pluginPath: './static/media/',
    }))
    setPlayer(newPlayer)
    return () => destroyPlayer(newPlayer)
  }, [id, options])


  return <div style={{ overflow: 'hidden' }}>
    <audio id={id} width={width} className="mejs__player" controls>
      {sources.map((source, index) => <source key={index} src={source.src} type={source.type} />)}
    </audio>
  </div>

  // Mejs sets javascript outside of react, so in order for that to work
  // `dangerouslySetInnerHTML` has to be used :/
  // TODO: get rid of mejs
  // const media = sources.map((source, index) => `<source key=${index} src=${source.src} type=${source.type} />`).join('')
  // const __html = `<audio id=${id} width=${width} className="mejs__player" controls>${media}</audio>`
  // return <div dangerouslySetInnerHTML={{ __html }}></div>
}

export default MediaElement

const destroyPlayer = (player: HTMLMediaElement) => {
  if (!player.paused) player.pause()
  player.remove()
}
