import { Artifact } from './Artifact'
import { AudioDescriptionKind, AudioDescriptionKindModel, DescriptionKind, DescriptionKindModel, DocumentKind, DocumentKindModel, EqTDKind, EqTDKindModel, FredsNotesKind, FredsNotesKindModel, Kinds, ObjectKind, ObjectKindModel, PictureKind, PictureKindModel, TranscriptKind, TranscriptKindModel, VideoKind, VideoKindModel } from './kinds'

export * from './kinds'
export * from './Artifact'
export * from './types'
export * from './addAnnotations'

type ArtifactWithoutKind = Omit<Artifact, 'kind'>
type ArtifactWithKind<T> = ArtifactWithoutKind & { readonly kind: T }

export interface DocumentArtifact extends ArtifactWithKind<DocumentKind> { }
export interface VideoArtifact extends ArtifactWithKind<VideoKind> { }
export interface DocumentArtifact extends ArtifactWithKind<DocumentKind> { }
export interface DescriptionArtifact extends ArtifactWithKind<DescriptionKind> { }
export interface ObjectArtifact extends ArtifactWithKind<ObjectKind> { }
export interface EqTDArtifact extends ArtifactWithKind<EqTDKind> { }
export interface TranscriptArtifact extends ArtifactWithKind<TranscriptKind> { }
export interface AudioDescriptionArtifact extends ArtifactWithKind<AudioDescriptionKind> { }
export interface PictureArtifact extends ArtifactWithKind<PictureKind> { }
export interface FredsNotesArtifact extends ArtifactWithKind<FredsNotesKind> { }

type MakeArtifactTypes<T> = T extends infer A ? ArtifactWithKind<A> : never;
type ArtifactTypes = MakeArtifactTypes<Kinds>
export type AnyArtifact = ArtifactTypes// | ArtifactWithKind<Kinds> 

export const createArtifactGuard = <T extends Kinds["name"]>(name: T) =>
  (artifact: ArtifactTypes): artifact is Extract<ArtifactTypes, { kind: { name: T } }> =>
    artifact.kind.name === name

export const ArtifactType = <T extends Kinds["name"]>(name: T) => (artifact: ArtifactTypes): artifact is Extract<ArtifactTypes, { kind: { name: T } }> => artifact.kind.name === name 

export const isVideoArtifact: (artifact: ArtifactTypes) => artifact is VideoArtifact = createArtifactGuard("Video")
export const isAudioDescriptionArtifact: (artifact: ArtifactTypes) => artifact is AudioDescriptionArtifact = createArtifactGuard("AudioDescription")
export const isDocumentArtifact: (artifact: ArtifactTypes) => artifact is DocumentArtifact = createArtifactGuard("Document")
export const isDescriptionArtifact: (artifact: ArtifactTypes) => artifact is DescriptionArtifact = createArtifactGuard("Description")
export const isObjectArtifact: (artifact: ArtifactTypes) => artifact is ObjectArtifact = createArtifactGuard("Object")
export const isEqTDArtifact: (artifact: ArtifactTypes) => artifact is EqTDArtifact = createArtifactGuard("EqTD")
export const isTranscriptArtifact: (artifact: ArtifactTypes) => artifact is TranscriptArtifact = createArtifactGuard("Transcript")
export const isPictureArtifact: (artifact: ArtifactTypes) => artifact is PictureArtifact = createArtifactGuard("Picture")
export const isFredsNotesArtifact: (artifact: ArtifactTypes) => artifact is FredsNotesArtifact = createArtifactGuard("FredsNotes")

export const KindModels = [
  VideoKindModel,
  DocumentKindModel,
  DescriptionKindModel,
  ObjectKindModel,
  EqTDKindModel,
  TranscriptKindModel,
  AudioDescriptionKindModel,
  PictureKindModel,
  FredsNotesKindModel,  
]
