import React from 'react'
import { config } from '../config'

export const NotFound = () =>
  <div>
    <h1>404 - Page Not Found</h1>
    <p>
      We're sorry, but the content you were trying to access
      cannot be found.  Please check the spelling of the link
      you were following, and try again.
    </p>
    <p>
      If you believe you've reached this page in error, please
      send an email to <a href="mailto:{config.admin_email}">{config.admin_email}</a>.
    </p>
  </div>

