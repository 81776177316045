import * as S from "@effect/schema/Schema"
import { ParseResult as PR, } from "@effect/schema"

import { $VideoKind, VideoKind } from "./Video"
import { $DocumentKind, DocumentKind } from "./Document"
import { $DescriptionKind, DescriptionKind } from "./Description"
import { $ObjectKind, ObjectKind } from "./Object"
import { $EqTDKind, EqTDKind } from "./EqTD"
import { $TranscriptKind, TranscriptKind } from "./Transcript"
import { $PictureKind, PictureKind } from "./Picture"
import { $AudioDescriptionKind, AudioDescriptionKind } from "./AudioDescription"
import { $FredsNotesKind, FredsNotesKind } from "./FredsNotes"
import { addAnnotations } from "../addAnnotations"

export const parseIssueTitle = ({ actual }: PR.ParseIssue) => {
  if (S.is(S.Struct({ name: S.String }))(actual)) return `Artifact Kind (${actual.name})`
}
export type $Kinds = typeof $Kinds
export type Kinds = S.Schema.Type<$Kinds>
export type KindsEncoded = S.Schema.Encoded<$Kinds>

export const $Kinds = S.Union(
  $VideoKind,
  $DocumentKind,
  $DescriptionKind,
  $ObjectKind,
  $EqTDKind,
  $TranscriptKind,
  $AudioDescriptionKind,
  $PictureKind,
  $FredsNotesKind,
).pipe(addAnnotations({
  identifier: 'Artifact-Kind',
  pretty: () => v => `Kind: ${v.name}`,
  parseIssueTitle,
}))

export * from "./Video"
export * from "./Document"
export * from "./Description"
export * from "./Object"
export * from "./EqTD"
export * from "./Transcript"
export * from "./Picture"
export * from "./AudioDescription"
export * from "./FredsNotes"
export * from './utils'

export const createKindGuard = <T extends Kinds["name"]>(name: T) => (kind: Kinds): kind is Extract<Kinds, { name: T }> =>
  kind.name === name

export const isVideoKind: (kind: Kinds) => kind is VideoKind = createKindGuard("Video")
export const isAudioDescriptionKind: (kind: Kinds) => kind is AudioDescriptionKind = createKindGuard("AudioDescription")
export const isDocumentKind: (kind: Kinds) => kind is DocumentKind = createKindGuard("Document")
export const isDescriptionKind: (kind: Kinds) => kind is DescriptionKind = createKindGuard("Description")
export const isObjectKind: (kind: Kinds) => kind is ObjectKind = createKindGuard("Object")
export const isEqTDKind: (kind: Kinds) => kind is EqTDKind = createKindGuard("EqTD")
export const isTranscriptKind: (kind: Kinds) => kind is TranscriptKind = createKindGuard("Transcript")
export const isPictureKind: (kind: Kinds) => kind is PictureKind = createKindGuard("Picture")
export const isFredsNotesKind: (kind: Kinds) => kind is FredsNotesKind = createKindGuard("FredsNotes")
