import * as S from "@effect/schema/Schema"
import { pipe } from "effect"
import { addAnnotations } from "../addAnnotations"

export const $YoutubeLink = pipe(
  S.TemplateLiteral(S.String),
  addAnnotations({ identifier: 'YoutubeLink' })
)

export const $YoutubeVideoURL = pipe(
  S.TemplateLiteral(S.Literal('https://www.youtube.com/watch?v='), S.String),
  addAnnotations({ identifier: 'Youtube Video URLl' }),
)

export const $YoutubeEmbedURL = pipe(
  S.TemplateLiteral(S.Literal('https://www.youtube.com/embed/'), S.String),
  addAnnotations({ identifier: 'Youtube Embed URL' })
)


// TODO: Everything below here belongs in `importer`
/**
 * Extracts the ID from a YouTube embed URL.
 * 
 * This function uses the URL object to parse the given URL 
 * and extract the last segment of the pathname, which represents the YouTube ID.
 * 
 * @param {string} urlString - The YouTube embed URL to parse.
 * @returns {string|null} The YouTube ID, or null if the URL format is incorrect.
 *
 * @example
 * // Example usage
 * const embedUrl = 'https://www.youtube.com/embed/dQw4w9WgXcQ';
 * console.log(extractYouTubeID(embedUrl)); // Outputs: dQw4w9WgXcQ
 */
export function YoutubeIDFromEmbed(urlString: string): string | null {
  const url = new URL(urlString)
  const pathSegments = url.pathname.split('/')
  return pathSegments.length > 2
    ? pathSegments[2]
    : null
}
export const YoutubeIdFromVideoURL = (url: string) => parseQueryParamValue(url, 'v')

/**
 * Extracts the value of a specified query parameter from a URL.
 * 
 * This function uses the URL and URLSearchParams objects to parse the given URL 
 * and extract the value of the desired query parameter.
 * 
 * @param {string} urlString - The URL string to parse.
 * @param {string} paramName - The name of the query parameter to extract.
 * @returns {string|null} The value of the query parameter, or null if not found.
 *
 * @example
 * // Example usage
 * const url = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&';
 * console.log(getQueryParamValue(url, 'v')); // Outputs: dQw4w9WgXcQ
 */
export function parseQueryParamValue(urlString: string, paramName: string): string | null {
  const url = new URL(urlString)
  const params = new URLSearchParams(url.search)
  return params.get(paramName)
}
