import React from 'react'
import { R2D2 } from '../utils'
import { config } from '../../config'

export const PrivacyPolicy = () =>
  <div>
    <h1>Privacy Policy</h1>
    <p>
      This privacy statement applies to the Fred Sammons Archives, developed in the Rehabilitation Research Design & Disability Center (<R2D2 />), Enderis Hall, Room 135, University of Wisconsin - Milwaukee, P.O. Box 413, Milwaukee, WI 53211-0413.
    </p>
    <p>
      The <R2D2 /> Center respects the privacy of the users of its websites and does not disclose, give, sell, or transfer any personal information about website visitors unless required to do so by law. The Center automatically collects a limited amount of information via Google’s Analytics software about the use of its websites for statistical purposes; for example, we count the number of our visitors, list national origins of visitors, assess how long visitors view pages, etc. This information is helpful when considering changes to make the site more useful to future visitors.
    </p>
    <p>
      Other information that Google Analytics may gather from a user includes:
    </p>
    <ul>
      <li>The Internet domain from which a user has accessed the Internet.</li>
      <li>The Internet protocol (IP) address of the computer that was used to access the Internet.</li>
      <li>The name and version of the user's computer operating system and Internet browser.</li>
      <li>The Internet address of the "referring" website, if a user comes to the Fred Sammons Archives from another website.</li>
      <li>The date, time, and duration of the user's visit to the website, and the pages visited.</li>
    </ul>
    <p>
      The Fred Sammons Archives may contain links to other websites. We cannot guarantee the privacy or security of information users provide to linked external websites.
    </p>
    <p>
      We collect information for the following purpose(s):
      <ul>
        <li>Completing or supporting an activity</li>
        <li>Website and system administration</li>
        <li>Research, analysis, reporting</li>
      </ul></p>
    <p>Information collected by our website is used by our organization only. Our website does not collect or store data that can identify its visitors.</p>
    <p>
      If you believe that our website has collected incorrect information or if you would like to dispute any information, please contact us at <a href="mailto:{config.admin_email}?subject=Fred Sammons Archives Inquiry">{config.admin_email}</a>.
    </p>
  </div>

