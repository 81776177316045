import React from 'react'
import { DocumentArtifact, PictureArtifact } from '@repo/schema'
import { EntryFC } from './types'
import { EntryLink, ReactFCC } from '../utils'
import { EntryProperty } from './utils'

//// All Artifact Kinds
export const Era: EntryFC = ({ entry: { chronology } }) =>
  <>{!!chronology && <EntryProperty label="Era">{chronology}</EntryProperty>}</>

//// Kind Specific
export const Categories: EntryFC<PictureArtifact | DocumentArtifact> = ({ entry: { kind: { categories } } }) =>
  <>{!!categories && <EntryProperty label="Categories">{categories}</EntryProperty>}</>

export const OtherEntries: EntryFC = ({ entry: { relatedArtifacts } }) => {
  if (!relatedArtifacts || relatedArtifacts.length === 0) return <></>
  const artifacts = relatedArtifacts
    .map(id => <li key={id}><EntryLink id={id}>Artifact #{id}</EntryLink></li>)

  return <EntryProperty label="Related Artifacts">
    <ul className="indentation">{artifacts}</ul>
  </EntryProperty>
}

export const ShortDescription: EntryFC = ({ entry }) =>
  entry.shortDescription
    ? <p>{entry.shortDescription}</p>
    : null

export const DownloadLink: ReactFCC<{ href: string }> = ({ href, children }) =>
  <div className="downloadButton"><a href={href}>{children}</a></div>

