import { pipe } from 'effect'
import { Api } from "effect-http"
import { $SearchResponse } from './SearchResultSchema'
import { $SearchParamsFromQuery } from './SearchParamsSchema'
import * as S from "@effect/schema/Schema"

export * from './SearchParamsSchema'
export * from './SearchResultSchema'
// export const $SearchResponse = FSA.$Artifact
export const $SearchQuery = S.Struct({ id: S.NumberFromString })

export const GetSearchResultsAPI = pipe(
  Api.get("SearchArtifacts", "/search/"),
  Api.setRequestQuery($SearchParamsFromQuery),
  Api.setResponseBody($SearchResponse),
)
