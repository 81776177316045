import React from 'react'
import { EqTDArtifact } from "@repo/schema"
import { EntryArtifactFC } from "../types"
import { EntryProperty, FetchError, useFetchState } from '../utils'

// TODO: Ideally effect platform would be used but
// that would require installing new packages and I don't want to re-create the hot patch
// I currently have on effect-http
// const fetchEqTD = (id: number) =>
//   pipe(
//     HttpClient.request.make('GET')(config.base_url() + `/media/${id}.txt`),
//     Effect.andThen(response => {
//       return Console.log('Got eqtd:', response.text)
//     }),
//   )

export const EqTD: EntryArtifactFC<EqTDArtifact> = ({ entry, artifact }) => {
  const state = useFetchState(entry)
  const { status } = state
  if (status === 'start' || status === 'loading') return <div>Loading Equivalent Text Description</div>
  if (status === 'error') return <FetchError message='EqTD' />
  return <ArtifactEqTD eqtd={state.data} />
}

// <ArtifactEqTD eqtd={entry.kind.} />
export const ArtifactEqTD: React.FC<{ eqtd: string }> = ({ eqtd }) => {
  const [brief, essential] = parseEqTD(eqtd)
  return <dl className="indentation">
    <EntryProperty label="Brief Description">{brief}</EntryProperty>
    <EntryProperty label="Essential Description">{essential}</EntryProperty>
  </dl>
}

const parseEqTD = (eqtd: string) => {
  const [rawBrief, rawEssential] = eqtd.split('\n')
  // brief description
  let brief = fixQuotes(rawBrief.substring(18, rawBrief.length))
  // long/detailed/essential description
  let essential = fixQuotes(rawEssential.substring(23, rawEssential.length))

  return [brief, essential] as const
}
const fixQuotes = (text: string) => text.replace("�", "''")

